import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

const MapHistoryControl = ({locInfo, setLocInfo}) => {
    const { t } = useTranslation();
    return <Paper
            elevation={2}
            sx={{
                position: 'fixed',
                top: (theme) => theme.spacing(31),
                right: (theme) => theme.spacing(2),
                flexDirection: 'column',
                borderRadius: '0.5em',
                display: 'flex',
            }}
        >
            <ButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em' }}
                orientation="vertical"
                aria-label="vertical contained button group"
                
            >
                <Button sx={{ p: { xs: 1, md: 1} }} 
                    title={t('icon_point_information')} 
                    onClick={() => setLocInfo(!locInfo)}
                    variant={!!locInfo ? "contained" : "text"}
                    color={!!locInfo ? "primary" : "neutral"}>
                        <NotListedLocationIcon />
                </Button>
            </ButtonGroup>
        </Paper>
     
}

export default MapHistoryControl;