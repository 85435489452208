import 'ol/ol.css';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { getArea, getLength } from 'ol/sphere';
import { getCenter } from 'ol/extent';
import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';

proj4.defs("MGI_M28","+proj=tmerc +lat_0=0 +lon_0=10.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.42319999999019 +units=m +no_defs +type=crs");
proj4.defs("MGI_M31","+proj=tmerc +lat_0=0 +lon_0=13.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.42319999999019 +units=m +no_defs +type=crs");
proj4.defs("MGI_M34","+proj=tmerc +lat_0=0 +lon_0=16.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.42319999999019 +units=m +no_defs +type=crs");
proj4.defs("UTM_32N","+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
proj4.defs("UTM_33N","+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
proj4.defs(
    'EPSG:31287',
    '+proj=lcc +lat_1=49 +lat_2=46 +lat_0=47.5 +lon_0=13.33333333333333 +x_0=400000 +y_0=400000 ' +
    '+ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +axis=neu +no_defs'
  );
register(proj4);



const dec2dms = number => {
    if (!number) return '-'
    let positive = true
    if (number < 0) {
        positive = false
        number = -number
    }

    const grad = Math.floor(number);
    const minutes = (number - grad) * 60;
    const clearMinutes = Math.floor(minutes);
    const secound = (minutes - clearMinutes) * 60;

    return `${!positive ? '-' : ''}${grad}° ${clearMinutes}' ${secound.toFixed(3)}''`;
}

// done
// Dezimalzahl also zB: "48,123 15,123" oder "48.123 15.123"
// N 47° 16′39″ W 8° 26′46″
// N 47° 16′39.1234″ W 008° 26′45.5678″
// 47° 16′39.1234″ N 8° 26′45.5678″W
// 47° 16′39,1234″ -8° 26′45,5678″
// 47d 16′39.1234″ N 8d 26′45.5678″ W

// open
// 47:16:39.1234″ N 8:26:45.5678″ W
// 47 16’39.1234″ N 8 26’45.45678″W

// For search to jump to a MapPoint instead of searchin in the backend
export const MapPoint = class {
    constructor(value) {
        this.isCoordinate = false
        this.type = false
        this.val = value;
        let match;


        let mgiPatterns = [
            /^(?<rw>-?\d+(\.\d+)?)[,;]?\s+(?<hw>-?\d{4,7}(\.\d+)?)[,;]?/,
            /^(?<rw>-?\d+(,\d+)?)[;]?\s+(?<hw>-?\d{4,7}(,\d+)?)[;]?/,
            /asdfasdf/,
        ]

        for (var v of mgiPatterns) {
            match = v.exec(this.val)
            if (match) {
                this.type = 'proj';
                this.isCoordinate = true
                this.objectType = 102
                this.rw = parseFloat(match.groups.rw)
                this.hw = parseFloat(match.groups.hw)
                if (this.hw > 5000000 && this.hw < 6000000) {
                    this.hw -= 5000000
                }
                return

                // TODO if MGI dann transform nach M28|31|34|32N|33N
                // SELECT ST_AsGeoJSON(ST_TRansform(ST_GeomFromText('POINT(-100000 318800)', 31256), 4326))
            }
        }




        let dmsPatterns = [
            /^(?<pdeg>\d{1,2}((\.|,)\d+)?)\s(?<ldeg>\d{1,3}((\.|,)\d+)?)/,
            /^(?<pdeg>(\d+))[°d]\s?(?<pmin>\d{1,2})[′'’´]\s?(?<psec>\d{1,2}([\.,]\d+)?)(''|″|"|´´|’’|′′|'')\s?(?<north>[NS\+-])\s(?<ldeg>(\d+))[°d]\s?(?<lmin>\d{1,2})[′'’´]\s?(?<lsec>\d{1,2}([\.,]\d+)?)(''|″|"|´´|’’|′′|'')\s?(?<east>[WE\+-])/,
            /^(?<north>[NS\+-]?)\s?(?<pdeg>(\d+))[°d]\s?(?<pmin>\d{1,2})[′'’´]\s?(?<psec>\d{1,2}([\.,]\d+)?)(''|″|"|´´|’’|′′|'')\s(?<east>[WE\+-]?)\s?(?<ldeg>(\d+))[°d]\s?(?<lmin>\d{1,2})[′'’´]\s?(?<lsec>\d{1,2}([\.,]\d+)?)(''|″|"|´´|’’|′′|'')/,
        ]

        for (v of dmsPatterns) {
            match = v.exec(this.val)
            if (match) {
                this.type = 'geog';
                this.isCoordinate = true
                this.objectType = 101
                this.phi = parseFloat(match.groups.pdeg.replace(/\,/, "."))
                this.pmin = parseFloat(match.groups.pmin) / 60 || 0
                this.psec = parseFloat(match.groups.psec) / 3600 || 0

                this.phi += this.pmin + this.psec
                this.north = match.groups.north?.replace(/\-/, 'S') || 'N'
                if (this.north === 'S') { this.phi = -this.phi }

                this.lam = parseFloat(match.groups.ldeg.replace(/\,/, "."))
                this.lmin = parseFloat(match.groups.lmin) / 60 || 0
                this.lsec = parseFloat(match.groups.lsec) / 3600 || 0

                this.lam += this.lmin + this.lsec
                this.east = match.groups.east?.replace(/\-/, 'W') || 'E'
                if (this.east === 'W') { this.lam = -this.lam }

                return
            }
        }
    }

    coordText = function(art="mgi") {
        if (this.isCoordinate) {
            if (this.type === 'geog') {
                return `λ=${dec2dms(this.lam)}, φ=${dec2dms(this.phi)}`;
            } else if (this.type === 'proj') {
                if (art == 'mgi') {
                    return `RW=${this.rw.toFixed(1)}, HW=${this.hw.toFixed(1)}`
                } else {
                    return `RW=${this.rw.toFixed(1)}, HW=${(this.hw + 5000000).toFixed(1)}`
                }
            } else {
                return 'hm'
            }
        } else {
            return null
        }
    }


    get geoJson() {
        let features;
        if (this.type === 'proj') {
            features = [
                { type: "Feature", id: 1, properties: { name: this.coordText(), objectType: 102}, 
                    geometry: { type: "Point", coordinates: proj4('MGI_M28').inverse([this.rw, this.hw]) }},
                { type: "Feature", id: 1, properties: { name: this.coordText(), objectType: 103}, 
                    geometry: { type: "Point", coordinates: proj4('MGI_M31').inverse([this.rw, this.hw]) }},
                { type: "Feature", id: 1, properties: { name: this.coordText(), objectType: 104}, 
                    geometry: { type: "Point", coordinates: proj4('MGI_M34').inverse([this.rw, this.hw]) }},
                { type: "Feature", id: 1, properties: { name: this.coordText('utm'), objectType: 105}, 
                    geometry: { type: "Point", coordinates: proj4('UTM_32N').inverse([this.rw, this.hw + 5000000]) }},
                { type: "Feature", id: 1, properties: { name: this.coordText('utm'), objectType: 106}, 
                    geometry: { type: "Point", coordinates: proj4('UTM_33N').inverse([this.rw, this.hw + 5000000]) }}
            ]
        } else {
            features = [
                {
                    type: "Feature",
                    id: 1,
                    properties: {
                        name: this.coordText('geog'),
                        objectType: this.objectType
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [this.lam, this.phi]
                    },
                }
            ]
        }


        let data = {
            searchTerm: this.val,
            data: {
                type: "FeatureCollection",
                features: features

            }
        }
        return data
    }

}

// Calculates the size of the next jump via arelative movement parameterand returns an array with the relativ movement up/down or left/right
export const getMovement = (moveMap, relativeMovement) => {
    const movement = relativeMovement ?? 0.66
    const size = moveMap.getSize();
    const view = moveMap.getView();
    const extent = view.calculateExtent(size);
    const leftRightMoveSize = extent[2] - extent[0];
    const upDownMoveSize = extent[3] - extent[1];
    return [leftRightMoveSize * movement, upDownMoveSize * movement];
}

// Calculates the coordinates of the next center via the getMovement function and a Direction Code (ArrowLeft/Arrow/Right/ArrowDown/ArrowUp)
export const getNextCenter = (centerMap, direction, relativeMovement) => {
    const [leftRight, upDown] = getMovement(centerMap, relativeMovement);
    const view = centerMap.getView();
    const currentCenter = view.getCenter();
    switch (direction) {
        case 'ArrowLeft': return [currentCenter[0] - leftRight, currentCenter[1]];
        case 'ArrowRight': return [currentCenter[0] + leftRight, currentCenter[1]];
        case 'ArrowDown': return [currentCenter[0], currentCenter[1] - upDown];
        case 'ArrowUp': return [currentCenter[0], currentCenter[1] + upDown];
        default: return currentCenter;
    }
}

export const zoomInOut = (map, plusMinus) => {
    if (!map) {
        return;
    }
    const view = map.getView();
    view.cancelAnimations();
    const zoom = view.getConstrainedZoom(view.getZoom() + (plusMinus === '+' ? 1 : -1));
    view.animate({ duration: 250, zoom });
}

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
export const formatLength = function (line) {
    const length = getLength(line);
    let output;
    if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
        output = Math.round(length * 100) / 100 + ' ' + 'm';
    }
    return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
export const formatArea = function (polygon) {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km²';
    } else {
        output = Math.round(area * 100) / 100 + ' ' + 'm²';
    }
    return output;
};

const minZoom = {
    'karte' : 5,
    'ortho' : 5,
    'epo_1' : 9,
    'epo_2' : 9,
    'epo_3' : 9,
    'epo_4' : 9,
    'epo_5' : 9,
    'epo_6' : 9,
    'epo_7' : 9,
    'epo_8' : 9,
}

const maxZoom = {
    'karte' : 16.5,
    'ortho' : 21,
    'epo_1' : 16.0,
    'epo_2' : 14.0,
    'epo_3' : 14.0,
    'epo_4' : 14.0,
    'epo_5' : 14.0,
    'epo_6' : 14.0,
    'epo_7' : 14.0,
    'epo_8' : 14.0,
}
// sets the min/max Zoom level of the view with the given Basis
// Todo finde Better solution with Resolution ?
export const setMinMaxZoomByBasis = (basis, comparison, map) => {
    comparison = comparison ? comparison : basis;

    const valMin = Math.max(minZoom[basis], minZoom[comparison]);
    const valMax = Math.min(maxZoom[basis], maxZoom[comparison]);
    const valCurrent = map.getView().getZoom();

    if (valCurrent < valMin || valCurrent > valMax) {
        const targetZoom = valCurrent < valMin ? valMin : valMax;
        map.getView().animate({zoom: targetZoom}, () => {
            map.getView().setMinZoom(valMin);
            map.getView().setMaxZoom(valMax);
        });
    } else {
        map.getView().setMinZoom(valMin);
        map.getView().setMaxZoom(valMax);
    }
}


// Extract data from the map and a GeoJson and returns them.
export const extractDataForZoom = (map, geoJson) => {
    const props = geoJson.properties;

    const vectorSource = new VectorSource({
        features: (new GeoJSON({ featureProjection: 'EPSG:3857' })).readFeatures(geoJson),
    });

    // Fit View for Extent of new layer
    const resolution = map.getView().getResolutionForExtent(vectorSource.getExtent());
    const resolutionZoom = map.getView().getZoomForResolution(resolution);
    const center = getCenter(vectorSource.getExtent());
    return { props, center, resolutionZoom }
}

// Basic function for KeyEvent Listener, for oure shortCuts
export const keyEventFunctionBasicTrigger = (e, locMap) => {
    if (e.target.localName.includes('input')) return;

    // +/- : zoom in or out from map
    if (e.key === '+' || e.key === '-') {
        zoomInOut(locMap, e.key);
    }

    // ctrl + ArrowKey : move around the map with only 2% of the Map Size
    if (e.ctrlKey && (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
        locMap.getView().animate({ center: getNextCenter(locMap, e.key, 0.02), duration: 250 });
        return;
    }

    // strg + ArrowKey : move around the map with only 22% of the Map Size
    if (e.shiftKey && !e.ctrlKey && (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
        locMap.getView().animate({ center: getNextCenter(locMap, e.key, 0.22), duration: 250 });
        return;
    }

    // ArrowKey : move around the map with only 66% of the Map Size
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        locMap.getView().animate({ center: getNextCenter(locMap, e.key), duration: 250 })
        return;
    }

    // ctrl + l : open layer switcher Dialog
    if (e.ctrlKey && e.key === "l") {
        e.preventDefault();
        return {layer: true} 
    }

    // ctrl + f : jump cursor to search
    if (e.ctrlKey && e.key === "f") {
        e.preventDefault();
        const elem = document.getElementById("search-field-map");
        elem.focus();
        return ;
    }

    // ctrl + m : show point in Center of map.
    if (e.ctrlKey && e.key === "m") {
        return {center: true} 
    }
}