import hashed from 'hashed';
import { deserialize } from 'hashed/lib/hash';
import { basisSet, comparisonSet} from 'routes/map'
import { layerDefinitions } from 'common/map/layers';
import apply from 'ol-hashed';

export function hashGet(value) {
  const hash = deserialize(window.location.hash);
  if (typeof (hash[value]) !== 'undefined') {
    return hash[value];
  } else {
    return null;
  }
}

function synchronize(map) {
  const unregisterMap = apply(map);
  const config = {
    basis: {
      default: '',
      serialize: function(value) {
        return value;
      },
      deserialize: (value) => Object.keys(layerDefinitions).includes(value) ? value : 'karte'
    },
    compare: {
        default: '',
        serialize: function(value) {
          return value;
        },
        deserialize: (value) => Object.keys(layerDefinitions).includes(value) ? value : 'epo_1'
    },
    // histtype: {
    //   default: '',
    //   serialize: function(value) {
    //     return value;
    //   },
    //   deserialize: String
    // },
    histmode: {
      default: false,
      serialize: function(value) {
        return value;
      },
      deserialize: (value) => value === 'true'
    },
};

  function hashHandler(state) {
    if (state.basis) {
      basisSet(state.basis);
    }

    if (state.compare) {
      comparisonSet(state.compare);
    }

  }

  return {
    unregister() {
      hashed.unregister(hashHandler);
      unregisterMap();
    },
    update: hashed.register(config, hashHandler)
  }
}

export default synchronize;
