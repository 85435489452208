import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';

const RotationControlls = ({isRotated, rotation, resetRotation }) => {
    
    if(!isRotated) return null;

    const grad = rotation * 180 / Math.PI ;

    return (
        <Paper
        id="ControllstesterRot"
            elevation={3}
            sx={{
                position: 'fixed',
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(8),
                zIndex: (theme) => theme.zIndex.drawer - 3,
                flexDirection: 'column',
                borderRadius: '0.5em',
                display: 'flex',
            }}
        >
            <ButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em' }}
                orientation="vertical"
                aria-label="vertical contained button group"
                variant="text"
                color="neutral"
            >
                <Button sx={{ p: { xs: 1, md: 1}  }} color={"neutral"} aria-label="start position tracking" onClick={resetRotation}><Icon sx={{transform:`rotate(${grad}deg)`}}>north</Icon></Button>
            </ButtonGroup>
        </Paper>
    );
}

export default RotationControlls