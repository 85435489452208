import React from "react";
import { MAP_URL as BASE_URL } from 'common/constants';

export const FetchContext = React.createContext();

const FetchProvider = ({ children }) => {

    const [objectsList, setObjectLists] = React.useState({});

    const fetchData = async (layer, id) => {
        try {
            if (objectsList[`${layer}/${id}`]) return objectsList[`${layer}/${id}`]
            const url = `${BASE_URL}api/${layer}/${id}/`;
            const options = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            };
            const response = await fetch(url, options);
            const data = await response.json();
            if(data.message) throw new Error(data.message);
            setObjectLists({ ...objectsList, [`${layer}/${id}`]: data });
            return data;
        } catch (e) {
            throw e;
        }
    }

    const fetchKG = async (kgnr) => {
        try {
            if (objectsList[`kgnr/${kgnr}`]) return objectsList[`kgnr/${kgnr}`]
            const url = `${BASE_URL}api/kgnr/${kgnr}/`;
            const options = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            };
            const response = await fetch(url, options);
            const data = await response.json();
            setObjectLists({ ...objectsList, [`kgnr/${kgnr}`]: data });
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    const fetchGst = async (kg, gnr) => {
        try {
            if (objectsList[`gst/${kg}/${gnr}`]) return objectsList[`gst/${kg}/${gnr}`]
            const url = `${BASE_URL}api/gst/${kg}/${gnr}/`;
            const options = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            };
            const response = await fetch(url, options);
            const data = await response.json();
            setObjectLists({ ...objectsList, [`gst/${kg}/${gnr}`]: data });
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    const value = {
        fetchData,
        fetchKG,
        fetchGst
    }

    return (
        <FetchContext.Provider value={value}>
            {children}
        </FetchContext.Provider>
    );
}

export default FetchProvider;