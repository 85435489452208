import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from "react-i18next";


export function ExportGPXIcon(props) {
    return (
        <SvgIcon {...props}>
                <path d="m 19,7.5000002 -4,0 v -6 H 9.0000002 v 6 h -4 L 12,14.5 Z" />
                <path d="m 9.1354164,20.492485 q -0.46875,0.227865 -0.9733073,0.341797 -0.5045573,0.113932 -1.0416666,0.113932 -1.2141927,0 -1.9238281,-0.677083 -0.7096354,-0.680339 -0.7096354,-1.842448 0,-1.17513 0.7226562,-1.848958 0.7226563,-0.673828 1.9791667,-0.673828 0.485026,0 0.9277343,0.09115 0.4459635,0.09115 0.8398437,0.270183 v 1.005859 Q 8.5494789,17.041964 8.1458331,16.928032 7.7454425,16.8141 7.3417966,16.8141 q -0.7486979,0 -1.1555989,0.419922 -0.4036458,0.416666 -0.4036458,1.194661 0,0.771484 0.390625,1.191406 0.390625,0.419922 1.110026,0.419922 0.1953125,0 0.3613281,-0.02279 0.1692708,-0.02604 0.3027344,-0.07813 v -0.944011 h -0.764974 v -0.839843 h 1.953125 z" />
                <path d="m 10.235677,15.993787 h 2.080078 q 0.927734,0 1.422526,0.413412 0.498047,0.410156 0.498047,1.171875 0,0.764974 -0.498047,1.178385 -0.494792,0.410156 -1.422526,0.410156 h -0.826823 v 1.686198 h -1.253255 z m 1.253255,0.908203 v 1.357422 h 0.693359 q 0.364584,0 0.563151,-0.175781 0.198568,-0.179037 0.198568,-0.504557 0,-0.325521 -0.198568,-0.501302 -0.198567,-0.175782 -0.563151,-0.175782 z" />
                <path d="m 17.826823,18.373344 1.686198,2.480469 h -1.305339 l -1.136068,-1.660156 -1.126302,1.660156 h -1.311849 l 1.686198,-2.480469 -1.621093,-2.379557 h 1.308593 l 1.064453,1.565755 1.061198,-1.565755 h 1.315104 z" />
        </SvgIcon>
    );
}


export function ExportPDFIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="m 19,7.5000002 -4,0 v -6 H 9.0000002 v 6 h -4 L 12,14.5 Z" />
            <path d="m 5.1005861,15.993787 h 2.0800781 q 0.9277343,0 1.422526,0.413412 0.4980468,0.410156 0.4980468,1.171875 0,0.764974 -0.4980468,1.178385 -0.4947917,0.410156 -1.422526,0.410156 H 6.3538413 v 1.686198 H 5.1005861 Z m 1.2532552,0.908203 v 1.357422 h 0.6933593 q 0.3645833,0 0.563151,-0.175781 0.1985678,-0.179037 0.1985678,-0.504557 0,-0.325521 -0.1985678,-0.501302 Q 7.4117839,16.90199 7.0472006,16.90199 Z" />
            <path d="m 11.236654,16.941053 v 2.965495 h 0.449218 q 0.76823,0 1.171875,-0.38086 0.406902,-0.380859 0.406902,-1.106771 0,-0.722656 -0.403646,-1.10026 -0.403646,-0.377604 -1.175131,-0.377604 z M 9.9833986,15.993787 h 1.3216144 q 1.106771,0 1.647136,0.159505 0.543619,0.15625 0.930989,0.533855 0.341797,0.328776 0.507813,0.758463 0.166015,0.429688 0.166015,0.973307 0,0.550131 -0.166015,0.983073 -0.166016,0.429688 -0.507813,0.758464 -0.390625,0.377604 -0.9375,0.537109 -0.546875,0.15625 -1.640625,0.15625 H 9.9833986 Z" />
            <path d="m 15.517252,15.993787 h 3.382161 v 0.947266 h -2.128906 v 0.904948 h 2.001953 v 0.947265 h -2.001953 v 2.060547 h -1.253255 z" />
        </SvgIcon>
    );
}

export function ExportPNGIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="m 19,7.5000002 -4,0 v -6 H 9.0000002 v 6 h -4 L 12,14.5 Z" />
            <path d="m 4.5813803,15.993787 h 2.080078 q 0.9277344,0 1.4225261,0.413412 0.4980468,0.410156 0.4980468,1.171875 0,0.764974 -0.4980468,1.178385 -0.4947917,0.410156 -1.4225261,0.410156 H 5.8346355 v 1.686198 H 4.5813803 Z m 1.2532552,0.908203 v 1.357422 h 0.6933593 q 0.3645833,0 0.563151,-0.175781 0.1985677,-0.179037 0.1985677,-0.504557 0,-0.325521 -0.1985677,-0.501302 Q 6.8925781,16.90199 6.5279948,16.90199 Z" />
            <path d="m 9.4641928,15.993787 h 1.3997392 l 1.767578,3.333333 v -3.333333 h 1.188151 v 4.860026 H 12.419922 L 10.652344,17.52048 v 3.333333 H 9.4641928 Z" />
            <path d="m 19.41862,20.492485 q -0.46875,0.227865 -0.973308,0.341797 -0.504557,0.113932 -1.041666,0.113932 -1.214193,0 -1.923828,-0.677083 -0.709636,-0.680339 -0.709636,-1.842448 0,-1.17513 0.722657,-1.848958 0.722656,-0.673828 1.979166,-0.673828 0.485026,0 0.927735,0.09115 0.445963,0.09115 0.839843,0.270183 v 1.005859 Q 18.832682,17.041964 18.429036,16.928032 18.028646,16.8141 17.625,16.8141 q -0.748698,0 -1.155599,0.419922 -0.403646,0.416666 -0.403646,1.194661 0,0.771484 0.390625,1.191406 0.390625,0.419922 1.110026,0.419922 0.195313,0 0.361328,-0.02279 0.169271,-0.02604 0.302735,-0.07813 v -0.944011 h -0.764974 v -0.839843 h 1.953125 z" />
        </SvgIcon>
    );
}


export function ExportGeoJSONIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="m 19,7.5000002 -4,0 v -6 H 9.0000002 v 6 h -4 L 12,14.5 Z" />
            <path d="M 3.8990822,19.92586 V 18.777162 H 2.9537696 v -0.475521 h 1.5182292 v 1.836198 q -0.3351562,0.237761 -0.7390625,0.360938 -0.4039062,0.120312 -0.8622396,0.120312 -1.0026042,0 -1.5697917,-0.584375 -0.5643229,-0.587239 -0.5643229,-1.632812 0,-1.048438 0.5643229,-1.632813 0.5671875,-0.587239 1.5697917,-0.587239 0.4182292,0 0.7934896,0.103125 0.378125,0.103125 0.6960938,0.303646 v 0.615885 q -0.3208333,-0.272135 -0.6817709,-0.409635 -0.3609375,-0.1375 -0.7591146,-0.1375 -0.7848958,0 -1.1802083,0.438281 -0.3924479,0.438281 -0.3924479,1.30625 0,0.865104 0.3924479,1.303385 0.3953125,0.438282 1.1802083,0.438282 0.3065105,0 0.5471355,-0.05156 0.240625,-0.05443 0.4325521,-0.166146 z"  />
            <path d="m 7.8532487,18.800079 v 0.257812 H 5.4298111 q 0.034375,0.544271 0.3265626,0.83073 0.295052,0.283593 0.8192708,0.283593 0.3036458,0 0.5872396,-0.07448 0.2864583,-0.07448 0.5671875,-0.223437 v 0.498437 q -0.2835937,0.120313 -0.5815104,0.183334 -0.2979167,0.06302 -0.6044271,0.06302 -0.7677084,0 -1.217448,-0.446875 -0.446875,-0.446875 -0.446875,-1.208854 0,-0.78776 0.4239584,-1.248958 0.4268229,-0.464063 1.1486979,-0.464063 0.6473959,0 1.0226563,0.418229 0.378125,0.415365 0.378125,1.131511 z M 7.3261654,18.645391 q -0.00573,-0.432552 -0.2434896,-0.690364 -0.2348959,-0.257813 -0.6244792,-0.257813 -0.4411459,0 -0.7075521,0.249219 -0.2635417,0.249219 -0.3036459,0.701823 z"  />
            <path d="m 9.5615819,17.697214 q -0.4239583,0 -0.6703125,0.332292 -0.2463542,0.329427 -0.2463542,0.905208 0,0.575782 0.2434896,0.908073 0.2463542,0.329427 0.6731771,0.329427 0.4210938,0 0.6674481,-0.332291 0.246354,-0.332292 0.246354,-0.905209 0,-0.570052 -0.246354,-0.902343 -0.2463543,-0.335157 -0.6674481,-0.335157 z m 0,-0.446875 q 0.6875001,0 1.0799481,0.446875 0.392448,0.446875 0.392448,1.2375 0,0.787761 -0.392448,1.2375 -0.392448,0.446875 -1.0799481,0.446875 -0.6903646,0 -1.0828125,-0.446875 -0.3895834,-0.449739 -0.3895834,-1.2375 0,-0.790625 0.3895834,-1.2375 0.3924479,-0.446875 1.0828125,-0.446875 z"  />
            <path d="m 11.527728,16.259193 h 0.578646 V 20.2381 q 0,0.773437 -0.295052,1.122916 -0.292187,0.34948 -0.942448,0.34948 h -0.220573 v -0.48698 h 0.180469 q 0.383854,0 0.541406,-0.214843 0.157552,-0.214844 0.157552,-0.770573 z"  />
            <path d="m 15.421738,16.399558 v 0.564323 q -0.329427,-0.157552 -0.621614,-0.234896 -0.292188,-0.07734 -0.564323,-0.07734 -0.472656,0 -0.730469,0.183334 -0.254948,0.183333 -0.254948,0.521354 0,0.283594 0.169011,0.429687 0.171875,0.14323 0.647395,0.232032 l 0.34948,0.07161 q 0.647395,0.123177 0.953906,0.435417 0.309375,0.309375 0.309375,0.830729 0,0.621615 -0.418229,0.942448 -0.415365,0.320833 -1.220313,0.320833 -0.303646,0 -0.647396,-0.06875 -0.340885,-0.06875 -0.707552,-0.203385 v -0.595833 q 0.352344,0.197656 0.690365,0.297916 0.338021,0.100261 0.664583,0.100261 0.495573,0 0.764844,-0.194792 0.269271,-0.194792 0.269271,-0.555729 0,-0.315104 -0.194792,-0.492708 -0.191927,-0.177605 -0.633073,-0.266407 l -0.352344,-0.06875 q -0.647395,-0.128906 -0.936718,-0.403906 -0.289323,-0.275 -0.289323,-0.764844 0,-0.567187 0.398177,-0.89375 0.401042,-0.326562 1.102864,-0.326562 0.300782,0 0.613021,0.05443 0.31224,0.05443 0.638802,0.163281 z"  />
            <path d="m 17.917832,16.651641 q -0.630208,0 -1.002604,0.469792 -0.369531,0.469792 -0.369531,1.280469 0,0.807812 0.369531,1.277604 0.372396,0.469792 1.002604,0.469792 0.630209,0 0.996875,-0.469792 0.369531,-0.469792 0.369531,-1.277604 0,-0.810677 -0.369531,-1.280469 -0.366666,-0.469792 -0.996875,-0.469792 z m 0,-0.469791 q 0.899479,0 1.438021,0.604427 0.538542,0.601562 0.538542,1.615625 0,1.011198 -0.538542,1.615625 -0.538542,0.601562 -1.438021,0.601562 -0.902344,0 -1.44375,-0.601562 -0.538542,-0.601563 -0.538542,-1.615625 0,-1.014063 0.538542,-1.615625 0.541406,-0.604427 1.44375,-0.604427 z"  />
            <path d="m 20.399602,16.259193 h 0.779167 l 1.896354,3.577865 v -3.577865 h 0.561459 v 4.276823 h -0.779167 l -1.896354,-3.577864 v 3.577864 h -0.561459 z"  />
        </SvgIcon>
    );
}


export function ImportGeoJSONIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="m 5.0000002,8.5 h 3.9999996 v 6 H 15 v -6 h 4 L 12,1.5000002 Z" />
            <path d="M 3.8990822,19.92586 V 18.777162 H 2.9537696 v -0.475521 h 1.5182292 v 1.836198 q -0.3351562,0.237761 -0.7390625,0.360938 -0.4039062,0.120312 -0.8622396,0.120312 -1.0026042,0 -1.5697917,-0.584375 -0.5643229,-0.587239 -0.5643229,-1.632812 0,-1.048438 0.5643229,-1.632813 0.5671875,-0.587239 1.5697917,-0.587239 0.4182292,0 0.7934896,0.103125 0.378125,0.103125 0.6960938,0.303646 v 0.615885 q -0.3208333,-0.272135 -0.6817709,-0.409635 -0.3609375,-0.1375 -0.7591146,-0.1375 -0.7848958,0 -1.1802083,0.438281 -0.3924479,0.438281 -0.3924479,1.30625 0,0.865104 0.3924479,1.303385 0.3953125,0.438282 1.1802083,0.438282 0.3065105,0 0.5471355,-0.05156 0.240625,-0.05443 0.4325521,-0.166146 z"  />
            <path d="m 7.8532487,18.800079 v 0.257812 H 5.4298111 q 0.034375,0.544271 0.3265626,0.83073 0.295052,0.283593 0.8192708,0.283593 0.3036458,0 0.5872396,-0.07448 0.2864583,-0.07448 0.5671875,-0.223437 v 0.498437 q -0.2835937,0.120313 -0.5815104,0.183334 -0.2979167,0.06302 -0.6044271,0.06302 -0.7677084,0 -1.217448,-0.446875 -0.446875,-0.446875 -0.446875,-1.208854 0,-0.78776 0.4239584,-1.248958 0.4268229,-0.464063 1.1486979,-0.464063 0.6473959,0 1.0226563,0.418229 0.378125,0.415365 0.378125,1.131511 z M 7.3261654,18.645391 q -0.00573,-0.432552 -0.2434896,-0.690364 -0.2348959,-0.257813 -0.6244792,-0.257813 -0.4411459,0 -0.7075521,0.249219 -0.2635417,0.249219 -0.3036459,0.701823 z"  />
            <path d="m 9.5615819,17.697214 q -0.4239583,0 -0.6703125,0.332292 -0.2463542,0.329427 -0.2463542,0.905208 0,0.575782 0.2434896,0.908073 0.2463542,0.329427 0.6731771,0.329427 0.4210938,0 0.6674481,-0.332291 0.246354,-0.332292 0.246354,-0.905209 0,-0.570052 -0.246354,-0.902343 -0.2463543,-0.335157 -0.6674481,-0.335157 z m 0,-0.446875 q 0.6875001,0 1.0799481,0.446875 0.392448,0.446875 0.392448,1.2375 0,0.787761 -0.392448,1.2375 -0.392448,0.446875 -1.0799481,0.446875 -0.6903646,0 -1.0828125,-0.446875 -0.3895834,-0.449739 -0.3895834,-1.2375 0,-0.790625 0.3895834,-1.2375 0.3924479,-0.446875 1.0828125,-0.446875 z"  />
            <path d="m 11.527728,16.259193 h 0.578646 V 20.2381 q 0,0.773437 -0.295052,1.122916 -0.292187,0.34948 -0.942448,0.34948 h -0.220573 v -0.48698 h 0.180469 q 0.383854,0 0.541406,-0.214843 0.157552,-0.214844 0.157552,-0.770573 z"  />
            <path d="m 15.421738,16.399558 v 0.564323 q -0.329427,-0.157552 -0.621614,-0.234896 -0.292188,-0.07734 -0.564323,-0.07734 -0.472656,0 -0.730469,0.183334 -0.254948,0.183333 -0.254948,0.521354 0,0.283594 0.169011,0.429687 0.171875,0.14323 0.647395,0.232032 l 0.34948,0.07161 q 0.647395,0.123177 0.953906,0.435417 0.309375,0.309375 0.309375,0.830729 0,0.621615 -0.418229,0.942448 -0.415365,0.320833 -1.220313,0.320833 -0.303646,0 -0.647396,-0.06875 -0.340885,-0.06875 -0.707552,-0.203385 v -0.595833 q 0.352344,0.197656 0.690365,0.297916 0.338021,0.100261 0.664583,0.100261 0.495573,0 0.764844,-0.194792 0.269271,-0.194792 0.269271,-0.555729 0,-0.315104 -0.194792,-0.492708 -0.191927,-0.177605 -0.633073,-0.266407 l -0.352344,-0.06875 q -0.647395,-0.128906 -0.936718,-0.403906 -0.289323,-0.275 -0.289323,-0.764844 0,-0.567187 0.398177,-0.89375 0.401042,-0.326562 1.102864,-0.326562 0.300782,0 0.613021,0.05443 0.31224,0.05443 0.638802,0.163281 z"  />
            <path d="m 17.917832,16.651641 q -0.630208,0 -1.002604,0.469792 -0.369531,0.469792 -0.369531,1.280469 0,0.807812 0.369531,1.277604 0.372396,0.469792 1.002604,0.469792 0.630209,0 0.996875,-0.469792 0.369531,-0.469792 0.369531,-1.277604 0,-0.810677 -0.369531,-1.280469 -0.366666,-0.469792 -0.996875,-0.469792 z m 0,-0.469791 q 0.899479,0 1.438021,0.604427 0.538542,0.601562 0.538542,1.615625 0,1.011198 -0.538542,1.615625 -0.538542,0.601562 -1.438021,0.601562 -0.902344,0 -1.44375,-0.601562 -0.538542,-0.601563 -0.538542,-1.615625 0,-1.014063 0.538542,-1.615625 0.541406,-0.604427 1.44375,-0.604427 z"  />
            <path d="m 20.399602,16.259193 h 0.779167 l 1.896354,3.577865 v -3.577865 h 0.561459 v 4.276823 h -0.779167 l -1.896354,-3.577864 v 3.577864 h -0.561459 z"  />
        </SvgIcon>
    );
}


export function ImportGPXIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="m 5.0000002,8.5 h 3.9999996 v 6 H 15 v -6 h 4 L 12,1.5000002 Z" />
            <path d="m 8.9498698,20.492485 q -0.46875,0.227865 -0.9733073,0.341797 -0.5045573,0.113932 -1.0416667,0.113932 -1.2141926,0 -1.923828,-0.677083 -0.7096354,-0.680339 -0.7096354,-1.842448 0,-1.17513 0.7226562,-1.848958 0.7226562,-0.673828 1.9791666,-0.673828 0.485026,0 0.9277344,0.09115 0.4459635,0.09115 0.8398437,0.270183 v 1.005859 Q 8.3639323,17.041964 7.9602864,16.928032 7.5598958,16.8141 7.15625,16.8141 q -0.7486979,0 -1.1555989,0.419922 -0.4036459,0.416666 -0.4036459,1.194661 0,0.771484 0.390625,1.191406 0.390625,0.419922 1.1100261,0.419922 0.1953125,0 0.3613281,-0.02279 0.1692708,-0.02604 0.3027343,-0.07813 V 18.995089 H 6.9967448 v -0.839843 h 1.953125 z" />
            <path d="m 10.05013,15.993787 h 2.080078 q 0.927735,0 1.422526,0.413412 0.498047,0.410156 0.498047,1.171875 0,0.764974 -0.498047,1.178385 -0.494791,0.410156 -1.422526,0.410156 h -0.826823 v 1.686198 H 10.05013 Z m 1.253255,0.908203 v 1.357422 h 0.69336 q 0.364583,0 0.563151,-0.175781 0.198568,-0.179037 0.198568,-0.504557 0,-0.325521 -0.198568,-0.501302 -0.198568,-0.175782 -0.563151,-0.175782 z" />
            <path d="m 17.641276,18.373344 1.686198,2.480469 h -1.305339 l -1.136067,-1.660156 -1.126302,1.660156 h -1.311849 l 1.686198,-2.480469 -1.621094,-2.379557 h 1.308594 l 1.064453,1.565755 1.061198,-1.565755 h 1.315104 z" />
        </SvgIcon>
    );
}







const MapFlyoutSketch = ({ open, drawPoint, drawLine, drawPolygon, eraseAll, handleDrawMode, drawMode }) => {
    const { t } = useTranslation();

    if(!open) return null
    return (
        <Paper
            elevation={1}
            sx={{
                position: 'fixed',
                top: theme => `calc(${theme.spacing(6)} + 70px)`,
                right: (theme) => `calc(${theme.spacing(7)} + 1px)`,
                zIndex: (theme) => theme.zIndex.drawer - 3,
                flexDirection: 'column',
                borderRadius: '0.5em',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                display: 'flex',
                
            }}
        >
            <ToggleButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em', border: t => `solid 1px ${t.palette.primary.light}`, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightColor: 'rgba(0, 0, 0, 0.5)'}}
                aria-label="horizintal contained button group"
                variant="text"
                color="neutral"
                onChange={handleDrawMode}
                value={drawMode}
                exclusive
            >
                <ToggleButton sx={{ p: { xs: 1, md: 1} }} title={t("icon_draw_point")} aria-label="draw point" value="Point" onClick={drawPoint} ><Icon>place</Icon></ToggleButton>
                <ToggleButton sx={{ p: { xs: 1, md: 1} }} title={t("icon_draw_line")} aria-label="draw line" value="LineString" onClick={drawLine} ><Icon>timeline</Icon></ToggleButton>
                <ToggleButton sx={{ p: { xs: 1, md: 1} }} title={t("icon_draw_polygon")} aria-label="draw polygon" value="Polygon" onClick={drawPolygon} ><Icon>crop_square</Icon></ToggleButton>
                <Button sx={{ ml: 1, p: { xs: 1, md: 1}, borderLeft: '1px solid rgba(0, 0, 0, 0.5)'}} title={t("icon_erase_all")} aria-label="erase all" onClick={eraseAll} ><Icon>delete</Icon></Button>
            </ToggleButtonGroup>
        </Paper>
    )
}

export default MapFlyoutSketch


export const MapFlyoutImport = ({ open, importGeoJSON, importGPX }) => {
    const { t } = useTranslation();

    if(!open) return null
    return (
        <Paper
            elevation={1}
            sx={{
                position: 'fixed',
                top: theme => `calc(${theme.spacing(6)} + 111px)`,
                right: (theme) => `calc(${theme.spacing(7)} + 1px)`,
                zIndex: (theme) => theme.zIndex.drawer - 3,
                flexDirection: 'column',
                borderRadius: '0.5em',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                display: 'flex',
                
            }}
        >
            <ButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em', border: t => `solid 1px ${t.palette.primary.light}`, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightColor: 'rgba(0, 0, 0, 0.5)'}}
                aria-label="horizintal contained button group"
                variant="text"
                color="neutral"
            >
                <Button sx={{ p: { xs: 1, md: 1} }} title={t("icon_import_geojson")} aria-label="import geojson" onClick={importGeoJSON} ><ImportGeoJSONIcon /></Button>
                <Button sx={{ p: { xs: 1, md: 1} }} title={t("icon_import_gpx")} aria-label="import gpx" onClick={importGPX} ><ImportGPXIcon /></Button>
            </ButtonGroup>
        </Paper>
    )
}


export const MapFlyoutExport = ({ open, exportPNG, exportPDF, exportGeoJSON, exportGPX }) => {
    const { t } = useTranslation();

    if(!open) return null
    return (
        <Paper
            elevation={1}
            sx={{
                position: 'fixed',
                top: theme => `calc(${theme.spacing(6)} + 152px)`,
                right: (theme) => `calc(${theme.spacing(7)} + 1px)`,
                zIndex: (theme) => theme.zIndex.drawer - 3,
                flexDirection: 'column',
                borderRadius: '0.5em',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                display: 'flex',
                
            }}
        >
            <ButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em', border: t => `solid 1px ${t.palette.primary.light}`, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightColor: 'rgba(0, 0, 0, 0.5)'}}
                aria-label="horizintal contained button group"
                variant="text"
                color="neutral"
            >
                <Button sx={{ p: { xs: 1, md: 1} }} title={t("icon_download_png")} aria-label={t("icon_download_png")} onClick={exportPNG} ><ExportPNGIcon /></Button>
                <Button sx={{ p: { xs: 1, md: 1} }} title={t("icon_download_pdf")} aria-label={t("icon_download_pdf")} onClick={exportPDF} ><ExportPDFIcon /></Button>
                <Button sx={{ p: { xs: 1, md: 1} }} title={t("icon_download_geojson")} aria-label={t("icon_download_geojson")} onClick={exportGeoJSON} ><ExportGeoJSONIcon /></Button>
                <Button sx={{ p: { xs: 1, md: 1} }} title={t("icon_download_gpx")} aria-label={t("icon_download_gpx")} onClick={exportGPX} ><ExportGPXIcon /></Button>
            </ButtonGroup>
        </Paper>
    )
}


