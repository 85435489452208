import { Paper, Box, IconButton, Icon } from "@mui/material";
import i18next from "i18next";
import React from "react"

/**
 * @param {{hidden: boolean, data: import("../../routes/map").OverlayData, callClose: () => void}} overlayInfo 
 * @returns 
 */
const OverlayCoordinates = ({ hidden, data, callClose }) => {
    const {hdms, height, mgi, mgiStrip, utm, utmStrip} = data;

    if (hidden) return null;
    return <Paper elevation={2} sx={{ width: 'auto', height: 'auto', p: 1, boxSizing: 'border-box', display: 'flex', alignItems: 'flex-start' }} >
        <Box>
            <Box>WGS 84: {hdms}</Box>
            {mgi && <Box>MGI {mgiStrip}: RW {mgi[0].toLocaleString('de-AT', {maximumFractionDigits: 0})} m; HW {mgi[1].toLocaleString('de-AT', {maximumFractionDigits: 0})} m</Box>}
            {utm && <Box>UTM {utmStrip}: RW {utm[0].toLocaleString('de-AT', {maximumFractionDigits: 0})} m; HW {utm[1].toLocaleString('de-AT', {maximumFractionDigits: 0})} m</Box>}
            {!!height && (height === -9999 ? <Box>keine Höhe verfügbar</Box> : <Box>Höhe: {height.toLocaleString('de-AT', {maximumFractionDigits: 0})} m</Box>)}
        </Box>
        <IconButton onClick={callClose}><Icon>close</Icon></IconButton>
    </Paper>

}

export default OverlayCoordinates;