import React, { useEffect, useState } from "react";
import MapView from "routes/map";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import FontEot from 'assets/fonts/SourceSansPro-Regular.eot';
import FontWoff2 from 'assets/fonts/SourceSansPro-Regular.otf.woff2';
import FontWoff from 'assets/fonts/SourceSansPro-Regular.otf.woff';
import FontTtf from 'assets/fonts/SourceSansPro-Regular.ttf';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import { FetchProvider, Header } from "components";
import { ImprintDialog } from "components/dialogs";

import 'style.css';
import ShortCutDialog from "components/dialogs/shortCutDialog";
import HelpDialog from "components/dialogs/helpDialog";


const theme = createTheme({

  palette: {
    primary: {
      main: 'rgba(230, 50, 15, 1)',
      contrastText: '#fff',
    },
    neutral: {
      main: 'rgba(0, 0, 0, 0.64)',
      contrastText: '#fff',
    },
  },
  
  typography: {
    fontFamily: 'Source San Pro, Robot'
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Source San Pro';
          font-style: normal;
          font-weight: 400;
          src: url('${FontEot}');
          /* IE9 Compat Modes */
          src: local('Source San Pro'), local('Source San Pro'),
            url('${FontWoff2}') format('woff2'),
            url('${FontWoff}') format('woff'),
            url('${FontTtf}') format('truetype')
        }
      `,
    },
  },
});

function App() {
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
  const [isShortCutsOpen, setIsShortCutsOpen] = useState(false);
  const [isImprintModalOpen, setIsImprintModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [headerHistMode, setHeaderHistMode] = useState(false);
  const queryClient = new QueryClient()
  
  useEffect(() => {
    const keyEventFunction = e => {

      if (e.key === "?" || e.key === "F1") {
        e.preventDefault();
        setIsInstructionsOpen(true);
      }
    }

    document.addEventListener("keydown", keyEventFunction, false);


    return () => {
      document.removeEventListener("keydown", keyEventFunction, false);
    }
  }, [])

  return <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <FetchProvider>
        <CssBaseline />
        <Header openInstructions={() => setIsInstructionsOpen(true)}  
                openShortCuts={() => setIsShortCutsOpen(true)} 
                openImprint={() => setIsImprintModalOpen(true)} 
                openMenu={() => setIsMenuOpen(true)} 
                closeMenu={() => setIsMenuOpen(false)} 
                isMenuOpen={isMenuOpen}
                headerHistMode={headerHistMode} />

        <MapView setHeaderHistMode={setHeaderHistMode} />
        <ShortCutDialog open={isShortCutsOpen} closeFunction={() => setIsShortCutsOpen(false)} />
        <HelpDialog open={isInstructionsOpen} closeFunction={() => setIsInstructionsOpen(false)} />
        <ImprintDialog open={isImprintModalOpen} closeFunction={() => setIsImprintModalOpen(false)} />
      </FetchProvider>
    </QueryClientProvider>
  </ThemeProvider>
}

export default App;
