import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, Slide, IconButton, Icon, DialogTitle, Typography } from "@mui/material";
import { ExportPDFIcon, ExportPNGIcon, ExportGeoJSONIcon, ExportGPXIcon, ImportGPXIcon } from "components/mapFlyouts";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Header4 = (props) => <Typography variant="h4" sx={{color: 'primary.main', mt: 5}} {...props} />
const Header5 = (props) => <Typography variant="h5" sx={{color: 'primary.main', mt: 4}} {...props} />
const Header6 = (props) => <Typography variant="h6" sx={{color: 'primary.main', mt: 4}} {...props} />

const Helpdialog = ({ open, closeFunction }) => {
    const { t } = useTranslation();
    const iconStyle = { 
        borderRadius: '5px', 
        height: '30px', 
        width: '30px', 
        marginLeft: '10px', 
        marginBottom: '-5px', 
        color: 'black',
        border: `1px solid grey` 
    };

    return <Dialog open={open}
        fullWidth
        maxWidth="lg"
        onClose={closeFunction}
        TransitionComponent={Transition}
        keepMounted>
        <IconButton
            aria-label="close"
            onClick={closeFunction}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <Icon>close</Icon>
        </IconButton>
        <DialogTitle>{t("instructions_tabs_help")}</DialogTitle>
        <DialogContent>

            <Header4 gutterBottom>{t('help_1')}</Header4>
            <Typography variant="body1" gutterBottom>{t('help_1_1')}</Typography>

            <Header5 gutterBottom>{t('help_1_2')}</Header5>
            
            <Header6  gutterBottom>{t('help_1_3')}</Header6>
            <Typography variant="body1" gutterBottom>{t('help_1_4')}</Typography>
            
            <Header6  gutterBottom>{t('help_1_5')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>zoom_in</Icon></Button></ButtonGroup>&nbsp;
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>zoom_out</Icon></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_1_6')}</Typography>
            
            <Header6  gutterBottom>{t('help_1_7')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>home</Icon></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_1_8')}</Typography>

            <Header6  gutterBottom>{t('help_1_9')} 
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>layers</Icon></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_1_10')}</Typography>
  
            <Header6  gutterBottom>{t('help_1_11')}</Header6>
            <Typography variant="body1" gutterBottom>{t('help_1_12')}</Typography>
  
            <Header4  gutterBottom>{t('help_2')}</Header4>
            <Typography variant="body1" gutterBottom>{t('help_2_1')}</Typography>

            <Header5  gutterBottom>{t('help_2_2')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>draw</Icon></Button></ButtonGroup>
            </Header5>
            <Typography variant="body1" gutterBottom>{t('help_2_3')}</Typography>

            <Header6  gutterBottom>{t('help_2_4')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>place</Icon></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_2_5')}</Typography>
  
            <Header6  gutterBottom>{t('help_2_6')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>timeline</Icon></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_2_7')}</Typography>
            <Typography variant="body1" gutterBottom>{t('help_2_8')}</Typography>
  
            <Header6  gutterBottom>{t('help_2_9')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>crop_square</Icon></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_2_10')}</Typography>
            <Typography variant="body1" gutterBottom>{t('help_2_11')}</Typography>
            <Typography variant="body1" gutterBottom>{t('help_2_12')}</Typography>
  
            <Header5 gutterBottom>{t('help_2_13')}</Header5>
            <Typography variant="body1" gutterBottom>{t('help_2_14')}</Typography>
  
            <Header6  gutterBottom>{t('help_2_15')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>upload</Icon></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_2_16')}</Typography>
            <ul><li>GeoJSON</li><li>GPX</li></ul>
            <Typography variant="body1" gutterBottom>{t('help_2_17')}</Typography>
            <Typography variant="body1" gutterBottom>{t('help_2_18')}</Typography>
  
            <Header6  gutterBottom>{t('help_2_19')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>download</Icon></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_2_20')}</Typography>
            
            <Typography variant="body1" gutterBottom><b>{t('help_2_21')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><ExportPNGIcon /></Button></ButtonGroup>
            </b></Typography>
            <Typography variant="body1" gutterBottom>{t('help_2_22')}</Typography>
            
            <Typography variant="body1" gutterBottom><b>{t('help_2_23')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><ExportPDFIcon /></Button></ButtonGroup>
            </b></Typography>
            
            <Typography variant="body1" gutterBottom>{t('help_2_24')}</Typography>
            <Typography variant="body1" gutterBottom>{t('help_2_25')}</Typography>
            <ul>
                <li>{t('help_2_26')}</li>
                <li>{t('help_2_27')}</li>
                <li>{t('help_2_28')}</li>
            </ul>

            <Header6  gutterBottom>{t('help_2_29')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><ExportGeoJSONIcon /></Button></ButtonGroup>&nbsp;
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><ExportGPXIcon /></Button></ButtonGroup>
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_2_30')}</Typography>
            
            <Header6  gutterBottom>{t('help_2_31')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>restore</Icon></Button></ButtonGroup>&nbsp;
            </Header6>
            <Typography variant="body1" gutterBottom>{t('help_2_32')}</Typography>

  
            <Header4 variant="h4" gutterBottom>{t('help_3')}</Header4>
  
            <Header5 gutterBottom>{t('help_3_1')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>search</Icon></Button></ButtonGroup>
            </Header5>
            <Typography variant="body1" gutterBottom>{t('help_3_2')}</Typography>
  
            <Header6  gutterBottom>{t('help_3_3')}</Header6>
            <Typography variant="body1" gutterBottom>{t('help_3_4')}</Typography>
  
            <Header6  gutterBottom>{t('help_3_5')}</Header6>
            <Typography variant="body1" gutterBottom>{t('help_3_6')}</Typography>
            <Typography variant="body1" gutterBottom>{t('help_3_7')}</Typography>
  
            <Header6  gutterBottom>{t('help_3_8')}</Header6>
            <Typography variant="body1" gutterBottom>{t('help_3_9')}</Typography> 
            <Typography variant="body1" gutterBottom><b>{t('help_3_10')}</b></Typography>
            <ul>
                <li>48,123 15,123</li>
                <li>48.123 15.123</li>
                <li>N 47° 16′39″ W 8° 26′46″</li>
                <li>N 47° 16′39.1234″ W 008° 26′45.5678″</li>
                <li>47° 16′39.1234″ N 8° 26′45.5678″W</li>
                <li>47° 16′39,1234″ -8° 26′45,5678″</li>
                <li>47d 16′39.1234″ N 8d 26′45.5678″ W</li>
            </ul>

            <Typography variant="body1" gutterBottom><b>{t('help_3_11')}</b></Typography>
            <ul>
                <li>50000,123 5250000,123</li>
                <li>50000.123 5250000.123</li>
                <li>50000.123 250000.123 ({t('help_3_12')})</li>
            </ul>
            <Typography variant="body1" gutterBottom>{t('help_3_13')}</Typography>



            <Header5 gutterBottom>{t('help_3_14')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>info</Icon></Button></ButtonGroup>
            </Header5>
            <Typography variant="body1" gutterBottom>{t('help_3_15')}</Typography> 
            
            <Header5 gutterBottom>{t('help_3_15_1')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>quiz</Icon></Button></ButtonGroup>
            </Header5>
            <Typography variant="body1" gutterBottom>{t('help_3_15_2')}</Typography>
            
            <Header5 gutterBottom>{t('help_3_16')}
                <ButtonGroup color="neutral"><Button  sx={{ p: { xs: 0.8, md: 0.8} }} ><Icon>not_listed_location</Icon></Button></ButtonGroup>
            </Header5>
            <Typography variant="body1" gutterBottom>{t('help_3_17')}</Typography>
  
            <ul>
                <li>WGS 84</li>
                <li>MGI M31</li>
                <li>UTM 33N</li>
                <li>{t('help_3_18')}</li>
            </ul>

            <Typography variant="body1" gutterBottom>{t('help_3_19')}<a href="https://www.bev.gv.at/dam/bevgvat/PDF-Dateien/Vermessungswesen/Formatbeschreibungen-und-sonstige-Info/KOORD-SYS.pdf">{t('help_3_20')}</a>{t('help_3_21')}</Typography>
  
  
        </DialogContent>
    </Dialog>
}

export default Helpdialog;

