import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, Slide, IconButton, Icon, DialogTitle } from "@mui/material";
import MyKbd from "components/MyKbd";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ShortCutDialog = ({ open, closeFunction }) => {
    const { t } = useTranslation();

    return <Dialog open={open}
        fullWidth
        maxWidth="lg"
        onClose={closeFunction}
        TransitionComponent={Transition}
        keepMounted>
        <IconButton
            aria-label="close"
            onClick={closeFunction}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <Icon>close</Icon>
        </IconButton>
        <DialogTitle>{t("instructions_tabs_short_cuts")}</DialogTitle>
        <DialogContent>
            <MyKbd keys={['STRG', 'L']} label={t("key_strg_l")} />
            <MyKbd keys={['STRG', 'F']} label={t("key_strg_f")} />
            <MyKbd keys={['STRG', 'SHIFT', 'C']} label={t("key_strg_shift_c")} />
            <MyKbd keys={[['?', 'F1']]} label={t("key_question")} />
            <MyKbd keys={['ESC']} label={t("key_esc")} />
            <MyKbd keys={['TAB']} label={t("key_tab")} />
            <MyKbd keys={['ENTER']} label={t("key_enter")} />

            <MyKbd keys={[<Icon>add</Icon>]} label={t("key_plus")} />
            <MyKbd keys={[<Icon>remove</Icon>]} label={t("key_minus")} />

            <MyKbd keys={[<Icon>arrow_upward</Icon>]} label={t("key_arrow_up")} />
            <MyKbd keys={[<Icon>east</Icon>]} label={t("key_arrow_right")} />
            <MyKbd keys={[<Icon>arrow_downward</Icon>]} label={t("key_arrow_down")} />
            <MyKbd keys={[<Icon>west</Icon>]} label={t("key_arrow_left")} />

            <MyKbd keys={['SHIFT', [<Icon>arrow_upward</Icon>, <Icon>east</Icon>, <Icon>arrow_downward</Icon>, <Icon>west</Icon>]]} label={t("key_shift_arrow")} />

            <MyKbd keys={['STRG', [<Icon>arrow_upward</Icon>, <Icon>east</Icon>, <Icon>arrow_downward</Icon>, <Icon>west</Icon>]]} label={t("key_strg_arrow")} />
        </DialogContent>
    </Dialog>
}

export default ShortCutDialog;

