import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import { useTranslation } from "react-i18next";

const MapLayoutControlls = ({isWatching, startTracking, zoomToFullExtent, isHistMode }) => {
    const { t } = useTranslation();

    return (
        <Paper
            elevation={2}
            sx={{
                position: 'fixed',
                bottom: isHistMode ? { xs: 8*8 , sm: 8*2 } : { xs: 8*2 , sm: 8*2 },
                right: (theme) => theme.spacing(2),
                zIndex: (theme) => theme.zIndex.drawer - 3,
                flexDirection: 'column',
                borderRadius: '0.5em',
                display: 'flex',
            }}
        >
            <ButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em' }}
                orientation="vertical"
                aria-label="vertical contained button group"
                variant="text"
                color="neutral"
            >
                <Button sx={{ p: { xs: 1, md: 1}  }} color="neutral" title={t('icon_overview')} aria-label={t('icon_overview')} onClick={zoomToFullExtent}><Icon>home</Icon></Button>
                <Button sx={{ p: { xs: 1, md: 1}  }} 
                    color={isWatching ? "primary" : "neutral"} variant={!!isWatching ? "contained" : "text"}
                    title={t('icon_start_tracking')} onClick={startTracking}>
                        <Icon>my_location</Icon>
                </Button>
            </ButtonGroup>
        </Paper>
    );
}

export default MapLayoutControlls