import React from "react";
import {Grid, Paper, Button, ButtonGroup, Icon, IconButton, LinearProgress, CircularProgress} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from "react-i18next";
import { SmallLayerCard} from "components/layerCard";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import MapScaleLineControl from 'components/mapScaleLineControl';

const SmallLayerSwitcher = ({ layer , position, changeLayer, openLayerModal, playing, setPlaying, loadingLayer, map, buttonActive, showButton, loadingComparison, timeTravelProgress}) => {
    const { t } = useTranslation();
    const [hover, setHover] = React.useState(false);
    const [paused, setPaused] = React.useState(false);
    const leftSide = (position === "left" || position === "leftsolo");
    const theme = useTheme();
    const belowSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const styleLeft = {
        maxWidth: 'calc(100vw - 32px)',
        position: 'fixed',
        left: theme.spacing(2),
        bottom: theme.spacing(2),
        margin: 0,
        backgroundColor: '#fff',
        borderRadius: '0.5em',
        fontSize: '1em',
        padding: '0 0 0 0',
        zIndex: theme.zIndex.drawer + 3,
        border: t => `1px solid ${t.palette.primary.light}`,
    }
      
      const styleRight = {
        maxWidth: 'calc(100vw - 32px)',
        position: 'fixed',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
        margin: 0,
        backgroundColor: '#fff',
        borderRadius: '0.5em',
        fontSize: '1em',
        padding: '0 0 0 0',
        zIndex: theme.zIndex.drawer + 3,
        border: t => `1px solid ${t.palette.primary.light}`,
    }
      

    const iconPos = {
        position: 'fixed',
        bottom: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
        flexDirection: 'column',
        borderRadius: '0.5em',
        display: 'flex'
    };

    if (leftSide) {
        iconPos.left = theme.spacing(2);
    } else {
        iconPos.right = { xs: 8*2 , sm: 8*10 };
    }

    const layerName = "modal_layer_button_" + layer;

    function playControl(orthoFallBack) {
        if (!playing && layer === 'ortho') {
            orthoFallBack();
        }
        setPaused(false);
        setPlaying(!playing);
    }

    return (<>
                {showButton && !hover && <Paper
                    elevation={2}
                    sx={iconPos}
                >
                    <ButtonGroup
                        sx={{ p: '0', m: '0', borderRadius: '0.5em' }}
                        orientation="horizontal"
                        aria-label="horizontal contained button group"
                        variant="text"
                        color="neutral"
                    >
                        { leftSide ? <Button color={ playing ? 'primary' : 'neutral'} onClick={() => setHover(buttonActive && !hover)} sx={{ border: (paused || playing) ? '1px' : '0px!important', p: { xs: 1, md: 1} }} title={position === 'left' ? t('icon_layer_left') : t('icon_layer_switcher')}>
                                { playing && !loadingLayer && <LinearProgress  variant="determinate" value={timeTravelProgress} sx={{ position: 'absolute', width: '100%', height: 4, left: 0, top: 36}} />}
                                {playing && timeTravelProgress === 0 && <CircularProgress size={18} sx={{ position: 'absolute', left: 10, top: 10}} />}
                                <Icon>{!playing ||(playing && timeTravelProgress > 0) ? 'layers' : ''}</Icon>
                                &nbsp;&nbsp;{t(layerName)}
                            </Button> : <Button onClick={() => setHover(!hover)} sx={{ border: playing ? '1px' : '0px!important', p: { xs: 1, md: 1} }} title={t('icon_layer_right')}>{t(layerName)}&nbsp;&nbsp;<Icon>layers</Icon></Button>
                        }
                        {(playing || paused) && <Button color={ paused ? 'primary' : 'neutral'} sx={{ p: { xs: 1, md: 1} }} title={t("icon_play_pause")} onClick={() => {setPlaying(!playing); setPaused(!paused)}}><Icon>{paused ? 'play_circle' : 'pause_circle'}</Icon></Button>}
                        {(playing || paused) && <Button sx={{ p: { xs: 1, md: 1} }} title={t("icon_stop")} onClick={() => {setPlaying(false); setPaused(false)}}><Icon>stop_circle</Icon></Button>}
                    </ButtonGroup>
                    {position !== 'right' && <MapScaleLineControl map={map} />}
                </Paper>}
                { !!hover &&
                 <Paper
                     sx={leftSide ? styleLeft : styleRight}
                     onClose={() => setHover(false)}
                 >
                    <div style={{display: "inline-block"}}>


                       <Grid container sx={{ fontSize: '1rem', borderBottom: t => `1px solid ${t.palette.divider}` }}  >
                            <Grid container item xs={1}>
                                <IconButton onClick={() => setHover(false)} sx={{ pl: '14px' }} ><Icon title={t("icon_close")} aria-label="toggle legend">close</Icon></IconButton>
                            </Grid>
                            <Grid container item xs={6} sx={{ pl: '10px', py: '10px' }}>{position === 'left' ? t("choose_left_map") : (position === 'right' ? t("choose_right_map") : t("choose_map") )}</Grid>
                            {position === 'leftsolo' && <Grid container item xs={4} sx={{ pr: 2}} justifyContent="flex-start">

                                <Button size="small" sx={{ height: '32px', px: '6px', mt: '6px' }} color={ playing ? 'primary' : 'neutral'} variant='outlined' startIcon={playing ? <Icon>stop_circle</Icon> : <Icon>play_circle</Icon>} onClick={() => playControl(changeLayer('epo_1'))}>{t("time_travel")}</Button>
                            </Grid>}
                            {position === 'leftsolo' && <Grid container item xs={1} sx={{ pr: 1}} justifyContent="flex-end">
                                <IconButton onClick={() => {setPlaying(false); setHover(false); openLayerModal()}}><Icon title={t("icon_expand")} sx={{ color: '#666' }}>open_in_full</Icon></IconButton>
                            </Grid>}
                        </Grid>
                        <SmallLayerCard selected={layer === "karte"} handleClick={changeLayer('karte')} layer={'karte'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                        <SmallLayerCard selected={layer === "ortho"} handleClick={changeLayer('ortho')} layer={'ortho'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                        <div style={{width: 86, display: "inline-block"}} />
                        <SmallLayerCard selected={layer === "epo_1"} handleClick={changeLayer('epo_1')} layer={'epo_1'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                        <SmallLayerCard selected={layer === "epo_2"} handleClick={changeLayer('epo_2')} layer={'epo_2'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                        {!belowSmall && <hr style={{border: 0}}></hr>}
                        <SmallLayerCard selected={layer === "epo_3"} handleClick={changeLayer('epo_3')} layer={'epo_3'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                        <SmallLayerCard selected={layer === "epo_4"} handleClick={changeLayer('epo_4')} layer={'epo_4'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                        <SmallLayerCard selected={layer === "epo_5"} handleClick={changeLayer('epo_5')} layer={'epo_5'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                        <SmallLayerCard selected={layer === "epo_6"} handleClick={changeLayer('epo_6')} layer={'epo_6'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                        <SmallLayerCard selected={layer === "epo_7"} handleClick={changeLayer('epo_7')} layer={'epo_7'} alt={"Image"} layerAnim={layer} playing={playing} loading={loadingLayer} timeTravelProgress={timeTravelProgress} loadingComparison={loadingComparison} />
                    </div>
                </Paper>}

            </>
        );
    }



const sliderPos = {
    position: 'sticky',
    bottom: (theme) => theme.spacing(2),
    zIndex: (theme) => theme.zIndex.drawer + 1,
    flexDirection: 'column',
    display: 'flex',
    width: 300,
    margin: 'auto',
};

    
const marks = [
    { value: 1880, label: '1880', },
    { value: 1910, label: '1910', },
    { value: 1930, label: '1930', },
    { value: 1960, label: '1960', },
    { value: 1980, label: '1980', },
    { value: 2000, label: '2000', },
    { value: 2010, label: '2010', },
    { value: 2023, label: '2023', },
];
    
function valuetext(value) {
    return `histMap um ${value}`;
}

export function TimeSlider() {
    return (
    <Box sx={sliderPos} elevation={2} >
        <Slider
            aria-label="histMap"
            defaultValue={1880}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            marks={marks}
            min={1880}
            max={2023}
            step={null}
        />
    </Box>
    );
}



export default SmallLayerSwitcher