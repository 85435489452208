import { Container, Typography, Box, Link, Paper, ButtonGroup, Button, Icon } from "@mui/material";
import React, { useState, useEffect } from "react";
import logo_de from 'assets/logo_de.svg';
import logo_en from 'assets/logo_en.svg';
import headBack from 'assets/img/watchback_col_10.jpg';

import { useTranslation } from "react-i18next";
import { NavigationMenu } from "components";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';


const Header = ({ openInstructions, openShortCuts, openImprint, openMenu, closeMenu, isMenuOpen, headerHistMode=false}) => {
    const { t, i18n } = useTranslation();
    const [currentLng, setCurrentLng] = useState('');
    const titleParts = ['Austrian', 'Map'];

    useEffect(() => {
        setCurrentLng(i18n.language);
    }, [i18n.language])


    const changeLanguage = (lng) => e => {
        i18n.changeLanguage(lng);
    };

    return <Paper elevation={2} sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: (theme) => theme.zIndex.drawer - 1, borderRadius: 0 }}>
        <Container maxWidth={false}>
            <Box sx={{ backgroundRepeat : 'no-repeat', backgroundSize: '300px', backgroundPositionY: 'center', backgroundPositionX: { xs: 'calc(100vw - 380px)', md: 'calc(100vw - 530px)'}, backgroundImage: headerHistMode ? `url(${headBack})` : 'none', display: 'flex', justifyContent: 'space-between', minHeight: '70px', alignItems : {xs: 'center', md: 'flex-start'} }}>
                <Box sx={{ height: { xs: '50px', sm: '70px' } }}>
                    <Link href="https://bev.gv.at/" target="_blank" rel="noreferrer" sx={{ position: 'relative', left: t => `${t.spacing(1) * -1}px` }}>
                        <img style={{ height: '100%', widht: '100%' }} src={i18n.language.toLowerCase().includes("de") ? logo_de : logo_en} alt={"BEV-Logo"} />
                    </Link>
                </Box>
                <Box>
                    <Box sx={{ display: { xs: 'inline-block', md: 'none' }, textAlign: "right"}}>
                        <Typography variant="h4" style={{marginTop: '0px', color: 'black'}} variantMapping={{ h5: "h1" }} sx={{fontSize: { xs: '1.2rem', sm:'1.7rem', md: '2.125rem'}}}>{titleParts[0]}<br /><span>{headerHistMode && 'hist'}</span>{titleParts[1]}</Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' }, textAlign: "right", pr: 2}}>
                        <Typography variant="h4" style={{marginTop: '0px', color: 'black'}} 
                            variantMapping={{ h5: "h1" }} 
                            sx={{fontSize: { xs: '1.2rem', sm:'1.7rem', md: '2.125rem'}}}
                        >{titleParts[0]} <span>{headerHistMode && 'hist'}</span>
                        {titleParts[1]}</Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'inline-block', md: 'none' } }}>
                        <Tooltip title={t("search_input_menu_tooltip")}>
                            <IconButton id="search-button-menu" sx={{ ml:1,  display: {xs: 'flex', md: 'none'} }} aria-label="toggle menu" onClick={openMenu}>
                                <Icon>menu</Icon>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <ButtonGroup color="neutral" size="small" variant="text" aria-label="text button group" sx={{ '& .MuiButtonGroup-grouped': { minWidth: 60, paddingLeft: 2, paddingRight: 2 } }}>
                            <Button
                                color={currentLng?.toLowerCase().includes("en") ? "primary" : "neutral"}
                                startIcon={<Icon >language</Icon>}
                                onClick={changeLanguage('en')} >
                                EN
                            </Button>
                            <Button
                                color={currentLng?.toLowerCase().includes("de") ? "primary" : "neutral"}
                                startIcon={<Icon >language</Icon>}
                                onClick={changeLanguage('de')}>
                                DE
                            </Button>
                            <Button
                                startIcon={<Icon >help</Icon>}
                                onClick={openInstructions}>
                                {t("instructions_tabs_help")}
                            </Button>
                            <Button
                                startIcon={<Icon >keyboard_alt</Icon>}
                                onClick={openShortCuts}>
                                {t("instructions_tabs_short_cuts")}
                            </Button>
                            <Button
                                sx={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderRight: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderColor: "rgba(0, 0, 0, 0.5)"
                                }}
                                startIcon={<Icon >tv</Icon>}
                                component={Link}
                                target="_blank"
                                rel="noreferrer"
                                href={"https://youtu.be/vWvgmqQVisk"}>
                                {t("menu_modal_btn_video")}
                            </Button>
                            <Button
                                startIcon={<Icon >perm_contact_calendar</Icon>}
                                onClick={openImprint}>
                                {t("menu_modal_btn_imprint")} / {t("menu_modal_btn_contact")}
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Box>
            </Box>
        </Container>
        <Box sx={{ height: (t) => t.spacing(4), width: '100%', backgroundColor: (theme) => theme.palette.primary.main }} />
        <NavigationMenu
            open={isMenuOpen}
            closeFunction={closeMenu}
            openFunction={openMenu}
            openImprint={openImprint}
            openInstructions={openInstructions}
            openShortCuts={openShortCuts}
            lng={currentLng}
            setLng={changeLanguage}
        ></NavigationMenu>
    </Paper>
}

export default Header;