import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, LinearProgress, Typography } from "@mui/material";
import karte from 'assets/img/08_OEK50_2016_68x55.jpg';
import ortho from 'assets/img/00_Orthofoto_68x55.jpg';
import epo_1 from 'assets/img/01_3LA_1873_68x55.jpg';
import epo_2 from 'assets/img/02_SK_1915_68x55.jpg';
import epo_3 from 'assets/img/03_SK_1937_68x55.jpg';
import epo_4 from 'assets/img/04_OEK50_prov_1963_68x55.jpg'
import epo_5 from 'assets/img/05_OEK50_1985_68x55.jpg';
import epo_6 from 'assets/img/06_OEK50_1997_68x55.jpg';
import epo_7 from 'assets/img/07_OEK50_2008_68x55.jpg';
import karteGross from 'assets/img/08_OEK50_2016_176x150.jpg';
import orthoGross from 'assets/img/00_Orthofoto_176x150.jpg';
import epo_1Gross from 'assets/img/01_3LA_1873_176x150.jpg';
import epo_2Gross from 'assets/img/02_SK_1915_176x150.jpg';
import epo_3Gross from 'assets/img/03_SK_1937_176x150.jpg';
import epo_4Gross from 'assets/img/04_OEK50_prov_1963_176x150.jpg'
import epo_5Gross from 'assets/img/05_OEK50_1985_176x150.jpg';
import epo_6Gross from 'assets/img/06_OEK50_1997_176x150.jpg';
import epo_7Gross from 'assets/img/07_OEK50_2008_176x150.jpg';

const images = {
    'karte': karte,
    'ortho': ortho,
    'epo_1': epo_1,
    'epo_2': epo_2,
    'epo_3': epo_3,
    'epo_4': epo_4,
    'epo_5': epo_5,
    'epo_6': epo_6,
    'epo_7': epo_7,
}
const imagesGross = {
    'karte': karteGross,
    'ortho': orthoGross,
    'epo_1': epo_1Gross,
    'epo_2': epo_2Gross,
    'epo_3': epo_3Gross,
    'epo_4': epo_4Gross,
    'epo_5': epo_5Gross,
    'epo_6': epo_6Gross,
    'epo_7': epo_7Gross,
}

const LayerCard = ({ selected, handleClick, img, alt, title, subtitle }) => {
  return <Card raised={!selected} sx={{ width: 180, m: '16px', display: 'inline-block', border: "solid 2px", borderColor: selected ? (t => t.palette.primary.main) : "#999" }}>
      <CardActionArea onClick={handleClick} sx={{'& :hover': {backgroundColor: '#eee'}}}>
          <CardMedia
              component="img"
              width="176"
              height="150"
              image={imagesGross[img]}
              alt={alt}
          />
          <CardContent sx={{ minHeight: '75px', p: '8px' }}>
              <Typography gutterBottom variant="h6" component="div">
                  {title}
              </Typography>              
          </CardContent>
      </CardActionArea>
  </Card>
}

export const SmallLayerCard = ({ selected, handleClick, layer, alt, layerAnim, playing, loading, timeTravelProgress, loadingComparison }) => {
    const { t } = useTranslation();
    
    const title = t("modal_layer_small_" + layer)
    const inactive = playing && layer === 'ortho';

    return <Card raised={!selected && !inactive} sx={{ width: 70, m: '8px', display: 'inline-block', border: "solid 1px", opacity: inactive && !selected ? 0.5 : 1, borderColor: selected ? (t => t.palette.primary.main) : "#999" }}>
        <CardActionArea onClick={handleClick} sx={{'& :hover': {backgroundColor: loading !== layer ? '#eee' : 'none'}}}>
            {playing && !loading && layerAnim === layer && <LinearProgress variant="determinate" value={timeTravelProgress} sx={{ position: 'absolute', width: 68, height: 8, left: 0, top: 47}} />}
            {loading === layer && <CircularProgress size={30} sx={{ position: 'absolute', left: 19, top: 12}} />}
            {loadingComparison === layer && <CircularProgress size={30} sx={{ position: 'absolute', left: 19, top: 12}} />}
            <CardMedia
                component="img"
                width="68"
                height="55"
                image={images[layer]}
                alt={alt}
            />
            <CardContent sx={{ minHeight: '10px', p: '8px' }}>
                <Typography gutterBottom variant="p" component="div">
                    {title}
                </Typography>              
            </CardContent>
        </CardActionArea>
    </Card>
}

export default LayerCard;