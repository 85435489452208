import React, { useEffect, useState } from "react";
import {Box, Button} from '@mui/material'
import {useTranslation} from "react-i18next";

const CookieInfo = () => {
    const { t } = useTranslation();
    const [hasConsens, setHasConsens] = useState(false);

    useEffect(() => {
        let x = localStorage.getItem("cookieInfoRead")
        if  (x === "true") {
            setHasConsens(true);
        }
    })

    const handleClick = () => {
        setHasConsens(true);
        localStorage.setItem("cookieInfoRead", true);
    }



    if (!!hasConsens) return null
    return <Box sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 99999999999999999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'cente',
        alignItems: 'center',
        borderRadius: 0,
        background: 'rgba(0, 0, 0, 0.7)',
        color: '#fff',
        borderTop: 'solid 2px black',
        p: '24px'

    }}>
        <p>{t("cookie_info")}</p>
        <Button variant="contained" color="primary" onClick={handleClick}>{t("cookie_btn")}</Button>
    </Box>
}

export default CookieInfo;
