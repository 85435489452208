import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer} from "@mui/material";
import Icon from '@mui/material/Icon';


const NavigationMenu = ({open, closeFunction, openFunction, openImprint, openInstructions, lng, setLng}) => {
    const {t} = useTranslation();

    const triggerOpenImprint = (e) => {
        openImprint(e);
        closeFunction(e);
    }

    const triggerOpenInstructions = (e) => {
        openInstructions(e);
        closeFunction(e);
    }

    return <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={closeFunction}
        onOpen={openFunction}
        swipeAreaWidth={10}
    >
        <Box
            sx={{width: 250}}
            role="presentation"
        >
            <List>

                <ListItemButton onClick={closeFunction}>
                    <ListItemIcon>
                        <Icon >close</Icon>
                    </ListItemIcon>
                    <ListItemText primary={t("menu_modal_header")}/>
                </ListItemButton>

                <Divider/>

                <ListItemButton onClick={triggerOpenInstructions}>
                    <ListItemIcon>
                        <Icon >keyboard_alt</Icon>
                    </ListItemIcon>
                    <ListItemText primary={t("instructions_tabs_help")}/>
                </ListItemButton>
                <ListItemButton onClick={triggerOpenImprint}>
                    <ListItemIcon>
                        <Icon >perm_contact_calendar</Icon>
                    </ListItemIcon>
                    <ListItemText primary={t("menu_modal_btn_imprint") + " / " + t("menu_modal_btn_contact")}/>
                </ListItemButton>
                
                <ListItemButton component={"a"} href={"https://youtu.be/vWvgmqQVisk"}>
                    <ListItemIcon>
                        <Icon >tv</Icon>
                    </ListItemIcon>
                    <ListItemText primary={t("menu_modal_btn_video")}/>
                </ListItemButton>
                <Divider/>

                <ListItem>
                    <ListItemIcon>
                        <Icon >language</Icon>
                    </ListItemIcon>
                    <ListItemText primary={t("menu_modal_language")}/>
                </ListItem>
                <ListItemButton onClick={setLng('en')} selected={lng?.toLowerCase().includes("en")}>
                    <ListItemText primary={"EN"}/>
                </ListItemButton>
                <ListItemButton onClick={setLng('de')} selected={lng?.toLowerCase().includes("de") }>
                    <ListItemText primary={"DE"}/>
                </ListItemButton>

            </List>


        </Box>
    </SwipeableDrawer>
}

export default NavigationMenu;
