import { Paper, Slider } from "@mui/material";

const historyFader = ({handleFadeChange}) => {
  return (
    <Paper
        elevation={2}
        sx={{
            position: 'fixed',
            bottom: (theme) => theme.spacing(5),
            left: (theme) => theme.spacing(2),
            zIndex: (theme) => theme.zIndex.drawer - 3,
            flexDirection: 'column',
            width: 200,
            display: 'flex',
        }}
    >
        <Slider defaultValue={50} onChange={(_, value) => handleFadeChange(value)}/>
    </Paper>
  );
};

export default historyFader;