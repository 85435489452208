import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import { useTranslation } from "react-i18next";

const MapLegendButton = ({ open, openFunction, searchOpen }) => {
    const { t } = useTranslation();

    if (open) {
        return (
            <Paper
                elevation={2}
                sx={{
                    position: 'fixed',
                    top: (theme) => searchOpen ? `calc(143px + ${theme.spacing(6)})` : `calc(117px + ${theme.spacing(6)})`,
                    left: (theme) => theme.spacing(2),
                    zIndex: (theme) => theme.zIndex.drawer - 3,
                    flexDirection: 'column',
                    borderRadius: '0.5em',
                    display: 'flex',
                }}
            >
                <ButtonGroup
                    sx={{ p: '0', m: '0', borderRadius: '0.5em' }}
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    variant="text"
                    color="neutral"
                >
                    <Button sx={{ p: { xs: 1, md: 1} }} title={t('icon_legend')} aria-label="open layers selection" onClick={openFunction}><Icon>info</Icon></Button>
                </ButtonGroup>
            </Paper>
        )} else {return null;}
}

export default MapLegendButton