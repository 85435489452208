import 'ol/ol.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { toast } from 'react-toastify';

import { Paper, IconButton, Icon, Button, ButtonGroup, TextField, Container } from '@mui/material';
import { Box } from '@mui/system';

import Map from 'ol/Map.js';
import { Style, Circle, Fill, Stroke } from "ol/style";
import { fromLonLat, toLonLat } from "ol/proj";

import { createEmpty, extend, getCenter, isEmpty } from 'ol/extent';
import { toStringHDMS } from 'ol/coordinate';
import { unByKey } from 'ol/Observable';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

import { HeightProfile, HistoryFader, MapLegend, MapLegendButton, MapZoomControlls, MapPositionControlls, MapControlls, OverlayCoordinates, MapFlyoutSketch, MapFlyoutExport, MapFlyoutImport, RotationControlls, SmallLayerSwitcher } from 'components';
import { LayerDialog } from "components/dialogs";

import { watchPositionOptions, basic_get_options, MAP_URL, TIMETRAVEL_FRAME_DURATION, PLAY_LOOP } from 'common/constants';

import { ortho, view, markerLayer, centerLayer, generatePointerFeature, karte, generatePointerFeatureFromLonLat, createBasicOverlay, styleFunctionMarker, comparisonSlider, layerDefinitions, basisLayer, comparisonLayer, basisSource, comparisonSource, updateSlider } from 'common/map/layers';
import { getNextZoom, setMinMaxZoomByBasis, extractDataForZoom, keyEventFunctionBasicTrigger, zoomInOut } from 'common/map/utils';
import { drawPoint, drawLine, drawPolygon, dragAndDrop, eraseAll, draw } from 'common/map/draw';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import { GPX, GeoJSON } from 'ol/format';
import { getDistance } from 'ol/sphere';
import { Search as SearchInputComponent } from '@bevops/search'
import { ExportDialog } from '@bevops/map-pdf-export';
import Banner from 'components/cookieInfo';
import { DragRotate, Draw, PinchRotate } from 'ol/interaction';
import MapHistoryControl from 'components/mapHistoryControl';
// import HistoryDialog from 'components/dialogs/historyDialog';
import AdvertisingClock from 'components/advertisingClock';
import MapLocationInformationControl from 'components/mapLocationInformationControl';

import hash, { hashGet } from 'common/utils/hash';
import  RecordStories from 'components/recordStories';
import { now } from 'd3';
import { Pin } from '@mui/icons-material';

/**
 * @typedef {Object} OverlayData
 * @property {import("ol/coordinate").Coordinate} [maka]
 * @property {import("ol/coordinate").Coordinate} lonLat
 * @property {string} hdms
 * @property {number} [height]
 * @property {import("ol/coordinate").Coordinate} [mgi]
 * @property {string} [mgiStrip]
 * @property {import("ol/coordinate").Coordinate} [utm]
 * @property {string} [utmStrip]
 * @property {import("ol/layer").Vector} [layer]
 */

proj4.defs("MGI_M28", "+proj=tmerc +lat_0=0 +lon_0=10.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.42319999999019 +units=m +no_defs +type=crs");
proj4.defs("MGI_M31", "+proj=tmerc +lat_0=0 +lon_0=13.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.42319999999019 +units=m +no_defs +type=crs");
proj4.defs("MGI_M34", "+proj=tmerc +lat_0=0 +lon_0=16.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.42319999999019 +units=m +no_defs +type=crs");
proj4.defs("UTM_32N", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
proj4.defs("UTM_33N", "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
proj4.defs(
    'EPSG:31287',
    '+proj=lcc +lat_1=49 +lat_2=46 +lat_0=47.5 +lon_0=13.33333333333333 +x_0=400000 +y_0=400000 ' +
    '+ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +axis=neu +no_defs'
);
register(proj4);

export let basisSet;
export let comparisonSet;

const MapView = ({setHeaderHistMode}) => {
    const { t } = useTranslation();

    // FIXME
    // Habe das versucht über die Breakpoints zu machen, aber da passt was nicht..?? Deshalb erstmal quick and dirty
    // const initMedia = (useMediaQuery(theme.breakpoints.up('md')));
    const initMedia = window.innerWidth > 900;

    const [isMapLegendOpen, setIsMapLegendOpen] = useState(false);
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(initMedia);
    const [basis, setBasis] = useState('karte');
    const [comparison, setComparison] = useState('epo_1');
    const [loadingLayer, setLoadingLayer] = useState(null);
    const [loadingComparison, setLoadingComparison] = useState(null);
    const [timeTravelProgress, setTimeTravelProgress] = useState(100);
    const [playing, setPlaying] = useState(false);
    const [isHistMode, setIsHistMode] = useState(false);
    const [histType, setHistType] = useState('swipe'); // One of "swipe" | "fade" 
    const [recordStoryLevel, setRecordStoryLevel] = useState(0);
    const [locInfo, setLocInfo] = useState(true); 
    const [layer, setLayer] = useState(null);
    const [isFlyoutSketchOpen, setIsFlyoutSketchOpen] = useState(false);
    const [isFlyoutImportOpen, setIsFlyoutImportOpen] = useState(false);
    const [isFlyoutExportOpen, setIsFlyoutExportOpen] = useState(false);
    const [isLayerModalOpen, setIsLayerModalOpen] = useState(false);
    const [isHistoryButtonActive, setIsHistoryButtonActive] = useState(false || localStorage.getItem('clockShown'));
    const [isClockAdFinished, setIsClockAdFinished] = useState(localStorage.getItem('clockShown'));
    const [positionWatchingOn, setPositionWatchinOn] = useState(false);
    const [overlayHidden, toggleOverlayHidden] = useState(true);
    const [overlayChangeNameHidden, toggleOverlayChangeNameHidden] = useState(true);
    const [overlayData, setOverlayDataObject] = useState(false);
    const [isExportModalOpen, setExportModalOpen] = useState(false);
    const [heightData, setHeightData] = useState(null);
    const [zoom, setZoom] = useState(null);
    const [linePos, setLinePos] = useState(null);
    const [drawMode, setDrawMode] = useState(null);
    const [currentRotation, setCurrentRotation] = useState(0); // Radiant
    
    const [map, setMap] = useState(null);
    const mapRef = useRef(null);
    const overlayRef = useRef(null);
    const overlayChangeNameRef = useRef(null);
    const changeNameFeatureRef = useRef(null);
    /** @type {import('react').Ref<Overlay>} */
    const OverlayObjectRef = useRef(null);
    const OverlayChangeNameObjectRef = useRef(null);
    const BasisLayer = useRef(null);
    const ComparisonLayer = useRef(null);
    const PointerLayer = useRef(null);
    const posid = useRef(null);
    const CenterLayer = useRef(null);
    const CurrentLayer = useRef(null);
    const isPointShown = useRef(false);
    const isLocInfoShown = useRef(true);
    const PositionWatchLayer = useRef(null);
    const CoordinateClickLayer = useRef(null);
    /** @type {React.MutableRefObject<VectorLayer>} */
    const heightHighlightLayer = useRef(null);
    /** @type {React.MutableRefObject<VectorSource>} */
    const heightSource = useRef(null);
    const sliderValue = useRef(0.5);
    /** @type {React.MutableRefObject<VectorLayer>} */
    const comparisonSliderLayer = useRef(null);
    const travelTimer = useRef(null);
    const resumePlaying = useRef(false);
    const update = useRef(() => {})
    const showHeightProfile = useRef(false);

    basisSet = setBasis;
    comparisonSet = setComparison;

    /**
     * Create OL Map Object and save it to State set Keybindings
     */
    useEffect(() => {
        //Load items from Localstorages and do some checks around them
        const locBasis = hashGet('basis') ? hashGet('basis') : (localStorage.getItem('basis') ? localStorage.getItem('basis') : 'karte');;
        setBasis(locBasis);
        
        const locComparison = hashGet('compare') ? hashGet('compare') : (localStorage.getItem('comparison') ? localStorage.getItem('comparison') : 'epo_1');
        setComparison(locComparison);
        
        const locHistType = hashGet('histtype') ? hashGet('histtype') : (localStorage.getItem('histType') ? localStorage.getItem('histType') : 'swipe');
        setHistType(locHistType);

        const locHistMode = hashGet('histmode') ? hashGet('histmode') : false;
        setIsHistMode(locHistMode === 'true' ? true : false);

        const locLayer = localStorage.getItem("layer");
        if (!!locLayer) {
            setLayer(locLayer);
        }

        // Create Basic layers, that will be shown form the start
        BasisLayer.current = basisLayer;
        ComparisonLayer.current = comparisonLayer;

        const featureIcon = generatePointerFeatureFromLonLat([0, 0]);
        CurrentLayer.current = markerLayer(featureIcon);


        //Create heightHighlightLayer
        const hlFeature = new Feature(new Point([NaN, NaN]));
        hlFeature.setStyle(() => new Style({
            image: new Circle({
                radius: 6,
                fill: new Fill({ color: [0, 153, 255, 1] }),
                stroke: new Stroke({ color: 'white', width: 1.5 }),
            }),
            zIndex: Infinity,
        }));

        const hlSource = new VectorSource({ features: [hlFeature] });
        heightHighlightLayer.current = new VectorLayer({
            properties: {
                profileDrives: true,
            },
            source: hlSource,
            zIndex: 55
        });
        heightSource.current = new VectorSource();

        const trackProfilePath = e => {
            if (heightSource.current.isEmpty()) {
                return;
            }
            const closestFeature = heightSource.current.getClosestFeatureToCoordinate(e.coordinate);
            setLinePos(closestFeature.get('linePos'));
        };

        heightHighlightLayer.current.on('change:profileDrives', (e) => {
            if (e.target.get('profileDrives')) {
                setLinePos(null);
                locMap.un(['click', 'pointermove'], trackProfilePath);
                return;
            }
            locMap.on(['click', 'pointermove'], trackProfilePath);
        });

        //source.addFeature(profileButton);


        // Create Map object with the layers and the controlls
        const locMap = new Map({
            layers: [
                BasisLayer.current,
                ComparisonLayer.current,
                heightHighlightLayer.current
            ],
            view,
            controls: [],
        });

        // Add Map event listener
        locMap.on(['movestart'], e => {
            if (CenterLayer.current) locMap.removeLayer(CenterLayer.current);
        })

        locMap.on(['moveend'], e => {
            const view = locMap.getView();
            setZoom(view.getZoom());
            setCurrentRotation(view.getRotation());
            if (!isPointShown.current) return;
            const size = locMap.getSize();
            const extent = view.calculateExtent(size);
            const center = getCenter(extent);
            const pointer = generatePointerFeature(center);
            CenterLayer.current = centerLayer(pointer);
            locMap.addLayer(CenterLayer.current);
        });

        locMap.getInteractions().on('remove', e => {
            if (e.element instanceof Draw && e.element.get('mode')) {
                setDrawMode(null);
            }
        });

        const getFeaturesAtPixelOptions = {
            hitTolerance: 5,
            layerFilter: l => l !== PointerLayer.current && l !== PositionWatchLayer.current && l !== CoordinateClickLayer.current,
        };

        locMap.on('click', function (evt) {
            const { pixel } = evt;
            const features = locMap.getFeaturesAtPixel(pixel, getFeaturesAtPixelOptions);
            const overSketch = features.find(f => ['button', 'sketch'].includes(f.get('type')));
            let firstFeature = features[0];
            let coordinate;
            let layer;
            if (isLocInfoShown.current) {
                if (firstFeature && !overSketch) {
                    coordinate = firstFeature.getGeometry().getCoordinates();
                    //locMap.getView().animate({ center: coordinate, duration: 250 });
                } else if (!overSketch) {
                    if (CoordinateClickLayer.current) locMap.removeLayer(CoordinateClickLayer.current);
                    coordinate = evt.coordinate;
                    firstFeature = generatePointerFeature(coordinate);
                    layer = markerLayer(firstFeature, 'trip_origin');
                    CoordinateClickLayer.current = layer;

                    locMap.addLayer(CoordinateClickLayer.current);
                }
                if (coordinate) {
                    //Show coordinates data.
                    const hdms = toStringHDMS(toLonLat(coordinate));
                    setOverlayData({ 
                        maka: coordinate, 
                        lonLat: toLonLat(coordinate), 
                        zoom: evt.map.getView().getZoom(),
                        activeLayer: locBasis,
                        hdms, layer });
                    OverlayObjectRef.current.setPosition(coordinate);
                }
            }
        });

        //Event listener for Hover effect
        locMap.on('pointermove', function (evt) {
            if (evt.dragging) {
                return;
            }
            const { pixel } = evt;
            const features = locMap.getFeaturesAtPixel(pixel, getFeaturesAtPixelOptions);
            const overSketch = features.find(f => ['button', 'sketch'].includes(f.get('type')));
            const firstFeature = features[0];
            const element = evt.map.getTargetElement();
            element.style.cursor = firstFeature && !overSketch ? 'pointer' : '';
        });

        dragAndDrop(locMap, setHeightData);

        comparisonSliderLayer.current = comparisonSlider(locMap, sliderValue);

        //Set Map to State for later manipulation and to tigger an useEffect 
        setMap(locMap)

        // Create Function for key bindings and shortcuts
        const keyEventFunction = e => {
            const data = keyEventFunctionBasicTrigger(e, locMap);
            if (data) {
                if (data.layer) setIsLayerModalOpen(data.layer);
                if (data.center) {
                    if (isPointShown.current) {
                        isPointShown.current = false;
                        if (CenterLayer.current) locMap.removeLayer(CenterLayer.current);
                    } else {
                        isPointShown.current = true;
                        const view = locMap.getView();
                        const size = locMap.getSize();
                        const extent = view.calculateExtent(size);
                        const center = getCenter(extent);
                        const pointer = generatePointerFeature(center);
                        CenterLayer.current = centerLayer(pointer);
                        locMap.addLayer(CenterLayer.current);
                    }
                }
            }
        }

        //Add and Remove Key Bindings
        document.addEventListener("keydown", keyEventFunction, false);

        return () => {
            setMap(null);
            document.removeEventListener("keydown", keyEventFunction, false);
        }
    }, [])

    /**
     * On Map update set target new
     * Update map zoom
     */
    useEffect(() => {
        if (map) {
            const {update: updateHash} = hash(map);
            update.current = updateHash;

            if (mapRef.current.style.height === "") mapRef.current.style.height = 'calc(100vh - 104px)';
            map.setTarget(mapRef.current);
            const view = map.getView();

            if (hashGet('zoom')) {
                view.setZoom(hashGet('zoom'));
            } else {
                view.fit([...fromLonLat([9.5, 46.3]), ...fromLonLat([17.2, 49.2])]);
            }
            if (hashGet('center')) {
                const centerCords = hashGet('center').split(',');
                view.setCenter(fromLonLat([centerCords[0], centerCords[1]]));
            }

            OverlayObjectRef.current = createBasicOverlay(overlayRef.current);
            OverlayChangeNameObjectRef.current = createBasicOverlay(overlayChangeNameRef.current);

            map.addOverlay(OverlayObjectRef.current);
            map.addOverlay(OverlayChangeNameObjectRef.current);

            updateBasisAndComparison();

        }
    }, [map])

    useEffect(() => {
        heightSource.current.clear(true);
        if (!heightData) {
            return;
        }
        let linePos = 0;
        heightSource.current.addFeatures(heightData.point_list.map((p, i) => {
            if (i > 0) {
                linePos += getDistance(heightData.point_list[i - 1], heightData.point_list[i])
            }
            return new Feature({
                geometry: new Point(fromLonLat(p)),
                index: i,
                linePos
            })
        }));
    }, [heightData])

    useEffect(() => {
        isLocInfoShown.current = locInfo;
    }, [locInfo])

    useEffect(() => {
        if (!map) {
            return;
        }
        if (isHistMode) {
            resetNorth();
            map.getInteractions().forEach(interaction => {
                if (interaction instanceof DragRotate || interaction instanceof PinchRotate) {
                    interaction.setActive(false);
                }
            });
        } else {
            map.getInteractions().forEach(interaction => {
                if (interaction instanceof DragRotate || interaction instanceof PinchRotate) {
                    interaction.setActive(true);
                }
            });
        }
    }, [isHistMode, map]);
 
    /**
     * Gets some base Coordinates and Fetches Hight and MGI data from Server
     * @param {OverlayData} data 
     */
    const setOverlayData = async (data) => {
        toggleOverlayHidden(false);
        const nextData = { ...data };

        try {
            const height_url = `https://transformator.bev.gv.at/at.gv.bev.transformator/api/height/singleHeight?${new URLSearchParams({ point: `${data.lonLat[0]},${data.lonLat[1]}` })}`;
            //const heightJson = await fetch(height_url, basic_get_options).then(r => r.json());
            const heightJson = await fetch(height_url, basic_get_options).then(r => r.json())
            if (heightJson.error) throw new Error('HELP');

            //TODO Add mockup
            nextData.height = heightJson.height;
        } catch (e) {
            toast.error(t('toast_error_search'));
            console.error(e);
        }
        const lon = data.lonLat[0];
        const mgiStrip = lon < 11.8333333333 ? 'M28' : lon < 14.8333333333 ? 'M31' : 'M34';
        const utmStrip = lon < 12 ? '32N' : '33N';
        /*
        try {
            const trafo_url_austrian = 'http://10.70.2.39:80/at.gv.bev.transformator/api/v3.0/austrian/'
            const body_1 = { "geojson": { "type": "FeatureCollection", "name": "SinglePoint", "crs": {}, "filetype": "csv", "features": [{ "type": "Feature", "properties": { "name": "SP1" }, "geometry": { "type": "Point", "coordinates": [data.lonLat[0], data.lonLat[0], parseFloat(nextData.height)] } }] }, "source_crs": "etrs89_lph", "target_crs": `mgi_${mgiStrip.toLowerCase()}`, "target_height_system": "usage", "source_height_system": "usage", "transformation_method": "BEV::2001" }
            const resopnse = await fetch(trafo_url_austrian, { ...basic_post_options, body: JSON.stringify(body_1) }).then(r => r.json());
            nextData.mgi = resopnse?.result?.points?.features?.[0]?.geometry?.coordinates;
        } catch (e) {
            toast.error(t('toast_error_search'));
            console.error(e);
            nextData.mgi = 
        }
        */
        nextData.mgi = proj4('EPSG:4326', `MGI_${mgiStrip}`, data.lonLat);
        nextData.mgiStrip = mgiStrip;

        /*
        try {
            //TODO Add mockup
            const trafo_url_test = 'https://transformator.bev.gv.at/at.gv.bev.transformator/api/v3.0/advanced/'
            const body_2 = { "geojson": { "type": "FeatureCollection", "name": "SinglePoint", "crs": {}, "filetype": "csv", "features": [{ "type": "Feature", "properties": { "name": "SP1" }, "geometry": { "type": "Point", "coordinates": [10.9681, 47.0644, 101] } }] }, "source_crs": { "frame": "302", "epoch": 2002.56, "ellipsoid": "EPSG::7030", "prime_meridian": "EPSG::8901", "projection": "", "height_system": "ellps", "propTypes": {} }, "target_crs": { "frame": "301", "epoch": 2002.56, "ellipsoid": "EPSG::7004", "prime_meridian": "EPSG::8909", "projection": "", "heightsystem": "usage", "propTypes": {} } }
            const testGeoJson = await fetch(trafo_url_test, { ...basic_post_options, body: JSON.stringify(body_2) }).then(r => r.json());
            console.log(testGeoJson)

        } catch (e) {
            toast.error(t('toast_error_search'));
            console.error(e);
        }
        */
        nextData.utm = proj4('EPSG:4326', `UTM_${utmStrip}`, data.lonLat);
        nextData.utmStrip = utmStrip;

        setOverlayDataObject(nextData);
    }

    /**
     * Remove clicked position marker when closing overlay
     */
    useEffect(() => {
        if (overlayHidden && overlayData.layer) {
            map.removeLayer(overlayData.layer);
        }
    }, [overlayHidden]);

    const hideOverlayData = () => toggleOverlayHidden(true);
    const hideOverlayChangeNameData = () => toggleOverlayChangeNameHidden(true);

    /**
     * Location Tracking via GPS 
     */
    useEffect(() => {
        if (map) {
            if (positionWatchingOn) {
                startWatching();
                const event = map.on(['pointerdrag'], e => {
                    setPositionWatchinOn(false);
                });
                return () => unByKey(event);
            }
            else { stopWatching(); }
        }
    }, [positionWatchingOn, map])

    if (!PositionWatchLayer.current) {
        const watchPoint = new Point([NaN, NaN]);
        const watchFeature = new Feature(watchPoint);
        const watchSource = new VectorSource({ features: [watchFeature] });
        PositionWatchLayer.current = new VectorLayer({
            source: watchSource,
            style: styleFunctionMarker('my_location')
        });
    }

    const stopWatching = () => {
        PositionWatchLayer.current.setVisible(false);
        map.removeLayer(PositionWatchLayer.current);
        navigator.geolocation.clearWatch(posid.current);
    };

    const startWatching = () => {
        map.addLayer(PositionWatchLayer.current);
        map.getView().animate({ zoom: 15, duration: 250 });

        posid.current = navigator.geolocation.watchPosition(
            (position) => {
                const { coords } = position;
                const coord = fromLonLat([coords.longitude, coords.latitude]);
                PositionWatchLayer.current.getSource().getFeatures()[0].getGeometry().setCoordinates(coord);
                PositionWatchLayer.current.setVisible(true);
                map.getView().animate({ center: coord, duration: 250 });
            },
            (e) => console.error(e),
            watchPositionOptions
        );
    };

    useEffect(() => {
        if (!playing) {
            clearInterval(travelTimer.current);
            travelTimer.current = null;
        }
        if (!loadingLayer && playing) {
            const index = (PLAY_LOOP.indexOf(basis) + 1) % PLAY_LOOP.length;
            const nextLayer = PLAY_LOOP[index];
            if (travelTimer.current) {
                return;
            }
            let progress = 100;
            setTimeTravelProgress(progress);
            travelTimer.current = setInterval(() => {
                progress = progress - 10;
                setTimeTravelProgress(progress);
                if (progress === 0) {
                    setBasis(nextLayer);
                    clearInterval(travelTimer.current);
                    travelTimer.current = null;
                }
            }, 10 * Math.round(TIMETRAVEL_FRAME_DURATION / 100));
        }
    }, [playing, loadingLayer]);

    useEffect(() => {
        if (!map) {
            return;
        }
        setLoadingLayer(basis);
        if (resumePlaying.current) {
            resumePlaying.current = false;
            setPlaying(true);
        }
        map.once('rendercomplete', () => setLoadingLayer(null));
    }, [basis]);

    useEffect(() => {
        if (!map) {
            return;
        }
        setLoadingComparison(comparison);
        map.once('rendercomplete', () => setLoadingComparison(null));
    }, [comparison, map]);

    const updateBasisAndComparison = (locBasis, locComparison, locSliderMode) => {
        
        locBasis = locBasis ?? basis ?? "karte"
        locComparison = comparison ?? "epo_1";
        
        locComparison = isHistMode ? locComparison : null;
        if (locSliderMode === undefined) {
            locSliderMode = isHistMode ? histType : null;
        }
        
        if (!map) return;
        const defBasis = layerDefinitions[locBasis];
        const source = defBasis.source || basisSource;
        source.tileGrid = defBasis.tileGrid || source.tileGrid;
        source.setUrls(defBasis.url ? [defBasis.url] : source.getUrls());
        BasisLayer.current.setSource(source);
        if (defBasis.source) {
            basisSource.clear();
        }
        if (locComparison) {
            const defComparison = layerDefinitions[locComparison];
            const source = defComparison.source || comparisonSource;
            source.tileGrid = defComparison.tileGrid || source.tileGrid;
            source.setUrls(defComparison.url ? [defComparison.url] : source.getUrls());
            ComparisonLayer.current.setSource(source);
        }
        ComparisonLayer.current.setVisible(!!locComparison);
        setMinMaxZoomByBasis(locBasis, isHistMode && locComparison, map);
        updateSlider(isHistMode, sliderValue)
        comparisonSliderLayer.current.setVisible(isHistMode && locComparison && locSliderMode === 'swipe');
    };

    const zoomData = geoJson => {
        const { resolutionZoom, props, center } = extractDataForZoom(map, geoJson);
        if (props.objectType === 5) map.getView().animate({ zoom: Math.max(resolutionZoom, 14), center, duration: 500 });
        else if (props.objectType === "7701") map.getView().animate({ zoom: 16, center, duration: 500 });
        else map.getView().animate({ zoom: Math.min(resolutionZoom, 21), center, duration: 500 });

        if (PointerLayer.current) map.removeLayer(PointerLayer.current);
        const pointer = generatePointerFeature(center, geoJson.properties.name);
        pointer.set('type', 'search_result');
        PointerLayer.current = markerLayer(pointer, 'not_listed_location');

        map.addLayer(PointerLayer.current);
    }

    useEffect(() => {
        if (map && PointerLayer.current && PointerLayer.current.getSource().getFeatures()[0].get('type') === 'search_result') {
            map.removeLayer(PointerLayer.current);
        }
    }, [isSearchBoxOpen]);

    useEffect(() => {
        localStorage.setItem("basis", basis);
        localStorage.setItem("comparison", comparison);
        localStorage.setItem("histType", histType);
        localStorage.setItem("histMode", isHistMode);
        if (histType) {
            updateBasisAndComparison(basis, comparison, histType)
        } else {
            updateBasisAndComparison(basis, null, histType)
        }
        setIsLayerModalOpen(false);

        update.current({
            basis: basis,
            compare: comparison,
            histtype: histType,
            histmode: isHistMode
        });

        if (isHistMode || (basis !== 'karte' && basis !== 'ortho')) {
            setHeaderHistMode(true);
        } else {
            setHeaderHistMode(false);
        }
    }, [basis, comparison, histType, isHistMode])

    const handleChangeBasis = basis => e => {
        if (basis === 'karte' || basis === 'ortho') {
            setRecordStoryLevel(0)
        }

        if (playing && basis === 'ortho') {
            return;
        }

        if (playing) {
            resumePlaying.current = true;
        }
        setPlaying(false);
        setBasis(basis);
    }    
    
    const handleChangeComparison = layer => e => {
        setComparison(layer)
    }


    const handleChangeSliderMode = mode => {
        setHistType(mode);
        sliderValue.current = 0.5;
        updateBasisAndComparison(basis, comparison, mode);
    }

    const handleFadeChange = value => {
        sliderValue.current = value / 100;
        updateBasisAndComparison(basis, comparison, histType);
    }

    const handleSetLayer = layer => e => {
        localStorage.setItem("layer", layer);
        setLayer(layer);
        setIsLayerModalOpen(false);
    }


    const drawPointOnMap = event => drawPoint(map);
    const drawLineOnMap = event => drawLine(map, setHeightData, undefined, showHeightProfile);
    const drawPolygonOnMap = event => drawPolygon(map);
    const eraseAllOnMap = event => eraseAll(map, setHeightData);
    const handleDrawMode = (event, drawMode) => setDrawMode(drawMode);

    const createMapImage = (map) => {
        var mapCanvas = document.createElement("canvas");
        var size = map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        var mapContext = mapCanvas.getContext("2d");
        let i = 0;
        Array.prototype.forEach.call(
            map.getTargetElement().querySelectorAll(".ol-layer canvas"),
            function (canvas) {
                if (canvas.width > 0) {
                    i++;
                    var opacity = canvas.parentNode.style.opacity;
                    mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                    var transform = canvas.style.transform;
                    // Get the transform parameters from the style's transform matrix
                    var matrix = transform
                        .match(/^matrix\(([^\(]*)\)$/)[1]
                        .split(",")
                        .map(Number);
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
                    mapContext.drawImage(canvas, 0, 0);
                }
            }
        );

        if (navigator.msSaveBlob) {
            // link download attribute does not work on MS browsers
            navigator.msSaveBlob(mapCanvas.msToBlob(), "map.png");
        } else {
            const element = document.createElement("a");
            element.href = mapCanvas.toDataURL();
            element.download = "austrian_map_" + new Date().getTime() + ".png";
            element.style.display = "none";
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }

    }

    const exportFeatures = (format) => {
        const layers = map.getAllLayers().filter(l => l.get('type') === 'sketch');
        const features = layers.reduce((prev, cur) => {
            prev.push(...cur.getSource().getFeatures().filter(f => f.get('type') === 'sketch'));
            return prev;
        }, []).map(f => {
            const newFeature = f.clone();
            newFeature.set('type', undefined);
            return newFeature;
        });
        const serialized = format.writeFeatures(features, { featureProjection: 'EPSG:3857' });
        let extension, mime;
        if (format instanceof GeoJSON) {
            extension = 'geojson';
            mime = 'application/json';
        } else if (format instanceof GPX) {
            extension = 'gpx';
            mime = 'text/xml';
        }
        const link = document.createElement('a');
        link.href = `data:${mime};charset=utf-8,${encodeURIComponent(serialized)}`;
        link.download = `austrian_map_${new Date().getTime()}.${extension}`;
        link.click();
    }

    const changeNameOfFeature = () => {
        const ele = document.getElementById('test');
        changeNameFeatureRef.current.set("name", ele.value);
        toggleOverlayChangeNameHidden(true);
    }

    const importData = (format) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _ => {
            let files = Array.from(input.files);
            if (files.length) {
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    const features = format.readFeatures(event.target.result, {
                        featureProjection: 'EPSG:3857'
                    });
                    const extent = createEmpty();
                    features.forEach(feature => {
                        extend(extent, feature.getGeometry().getExtent());
                        draw(map, feature, setHeightData, showHeightProfile)
                    });
                    if (!isEmpty(extent)) {
                        map.getView().fit(extent, { duration: 500, padding: [50, 50, 50, 50] });
                    }
                });
                reader.readAsText(files[0]);
            };
        }
        input.click();
    }

    const resetNorth = () => {
        //FIXME animation does not work because map gets recreated (over-reactivity problem)
        map.getView().setRotation(0);
    }

    return (
        <div className="expanded">
            <div className={isHistMode ? 'scalehistmode' : (basis === 'karte' || basis === 'ortho' ? 'scaledefault' : 'scaletimeline')} ref={mapRef} id="map" style={{ marginTop: 104 }} />
            <div ref={overlayRef}>
                <OverlayCoordinates hidden={overlayHidden} data={overlayData} callClose={hideOverlayData} />
            </div>

            <div ref={overlayChangeNameRef}>
                {!overlayChangeNameHidden && <Paper elevation={2} sx={{ width: 'auto', height: 'auto', p: 1, boxSizing: 'border-box', display: 'flex', alignItems: 'flex-start' }} >
                    <Box>
                        <TextField id="test" />
                        <Button onClick={changeNameOfFeature}>Save</Button>
                    </Box>
                    <IconButton onClick={hideOverlayChangeNameData}><Icon>close</Icon></IconButton>
                </Paper>
                }
            </div>

            {recordStoryLevel === 2 &&

                <Box sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,.4)',
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }} />}


            {heightData &&
                <HeightProfile
                    heightData={heightData}
                    closeFunction={() => {
                        showHeightProfile.current = false;
                        setHeightData(null);
                        heightHighlightLayer.current.getSource().getFeatures()[0].getGeometry().setCoordinates([NaN, NaN]);
                    }}
                    setFeature={(p) => {
                        heightHighlightLayer.current.getSource().getFeatures()[0].getGeometry().setCoordinates(p ? fromLonLat(p.coordinates) : [NaN, NaN]);
                    }}
                    setVisibility={v => {
                        heightHighlightLayer.current.set('profileDrives', v);
                        heightHighlightLayer.current.getSource().getFeatures()[0].getGeometry().setCoordinates([NaN, NaN]);
                    }}
                    linePos={linePos}
                />}

            <Box sx={{
                position: 'fixed',
                top: (theme) => `calc(70px + ${theme.spacing(6)})`,
                left: (theme) => theme.spacing(2),
                bottom: isSearchBoxOpen ? t => t.spacing(2) : 'auto',
                right: isSearchBoxOpen ? t => t.spacing(2) : 'auto',
                marginRight: { xs: 8, sm: 8, md: 2 },
                zIndex: (theme) => theme.zIndex.drawer - 1,
            }}>
                <SearchInputComponent
                    handleSelectData={zoomData}
                    withBackdrop
                    initOpen
                    label={t("search_placeholder")}
                    size="big"
                    searchRouteText="all4map"
                    description={<SearchDescription />}
                    openStateChanged={(e) => setIsSearchBoxOpen(e)}
                    includeLayers={['BL', 'PB', 'GP', 'ADR', 'STR', 'CRD', 'GEO_SIEDLUNG', 'GEO_GEBIET', 'GEO_BERG', 'GEO_GLETSCHER', 'GEO_WASSER', 'GEO_SONSTIGE']}
                />
            </Box>

            <MapLegend
                open={isMapLegendOpen}
                searchOpen={true}
                closeFunction={() => setIsMapLegendOpen(false)}
                zoom={zoom}
                basis={basis}
                comparison={comparison}
                histmode={isHistMode}
            />
            
            <MapLegendButton
                open={!isMapLegendOpen}
                searchOpen={true}
                openFunction={() => setIsMapLegendOpen(true)}
            />

            <MapControlls
                flyOutOpen={isFlyoutSketchOpen}
                openFlyoutSketch={() => (setIsFlyoutSketchOpen(true), setIsFlyoutImportOpen(false), setIsFlyoutExportOpen(false))}
                closeFlyoutSketch={() => setIsFlyoutSketchOpen(false)}

                flyOutImportOpen={isFlyoutImportOpen}
                openFlyoutImport={() => (setIsFlyoutSketchOpen(false), setIsFlyoutImportOpen(true), setIsFlyoutExportOpen(false))}
                closeFlyoutImport={() => setIsFlyoutImportOpen(false)}

                flyOutExportOpen={isFlyoutExportOpen}
                openFlyoutExport={() => (setIsFlyoutSketchOpen(false), setIsFlyoutImportOpen(false), setIsFlyoutExportOpen(true))}
                closeFlyoutExport={() => setIsFlyoutExportOpen(false)}
                openExportModal={() => setExportModalOpen(true)}
            />

            <MapLocationInformationControl
                locInfo={locInfo}
                setLocInfo={setLocInfo}
            />
            <MapHistoryControl
                showButton={isHistoryButtonActive}
                elevated={!isClockAdFinished}
                isHistMode={isHistMode}
                setIsHistMode={setIsHistMode}
                setPlaying={setPlaying}
            />
            <AdvertisingClock 
                historyButtonActive={isHistoryButtonActive}
                clockAdActive={!isClockAdFinished}
                animationEnded={() => setIsHistoryButtonActive(true)}
                clockAdFinished={() => {setIsClockAdFinished(true); localStorage.setItem('clockShown', true)}}
            />

            <MapZoomControlls
                zoom={(direction) => e => zoomInOut(map, direction)}
                isHistMode={isHistMode}
            />
            <MapPositionControlls
                startTracking={() => setPositionWatchinOn(!positionWatchingOn)} isWatching={positionWatchingOn}
                zoomToFullExtent={() => map.getView().fit([...fromLonLat([9.5, 46.3]), ...fromLonLat([17.2, 49.2])], { duration: 500 })}
                isHistMode={isHistMode}
            />

            <RotationControlls isRotated={currentRotation !== 0} rotation={currentRotation} resetRotation={resetNorth} />

            <MapFlyoutSketch
                open={isFlyoutSketchOpen}
                drawPoint={drawPointOnMap}
                drawLine={drawLineOnMap}
                drawPolygon={drawPolygonOnMap}
                eraseAll={eraseAllOnMap}
                drawMode={drawMode}
                handleDrawMode={handleDrawMode}
            />

            <MapFlyoutImport
                open={isFlyoutImportOpen}
                importGeoJSON={() => importData(new GeoJSON())}
                importGPX={() => importData(new GPX())}
            />

            <MapFlyoutExport
                open={isFlyoutExportOpen}
                exportPDF={() => setExportModalOpen(true)}
                exportPNG={() => createMapImage(map)}
                exportGeoJSON={() => exportFeatures(new GeoJSON())}
                exportGPX={() => exportFeatures(new GPX())}
            />

            <LayerDialog open={isLayerModalOpen} 
                            closeFunction={() => setIsLayerModalOpen(false)} 
                            basis={basis} 
                            changeBasis={handleChangeBasis} 
                            showLayer={handleSetLayer} 
                            layer={layer} 
                            />
                            
            <RecordStories 
                    visible={(basis !== 'karte' && basis !== 'ortho')}
                    level={recordStoryLevel}
                    map={basis}
                    openFunction={() => setRecordStoryLevel(1)}
                    maxFunction={() => setRecordStoryLevel(2)}
                    closeFunction={() => setRecordStoryLevel(0)}
            />

            <ExportDialog
                open={isExportModalOpen}
                closeFunction={() => setExportModalOpen(false)}
                printMap={map}
                serviceName={"austrian_map"}
                title={"Austrian Map"}
            />

            <Banner />
            { !isHistMode && !isLayerModalOpen && <SmallLayerSwitcher
                layer={basis}
                position={"leftsolo"}
                changeLayer={handleChangeBasis}
                openLayerModal={() => setIsLayerModalOpen(true)}
                playing={playing}
                setPlaying={setPlaying}
                loadingLayer={loadingLayer}
                map={map}
                showButton={isHistoryButtonActive}
                buttonActive={isClockAdFinished}
                loadingComparison={loadingComparison}
                timeTravelProgress={timeTravelProgress}
            /> }
            { isHistMode && histType === 'swipe' && <SmallLayerSwitcher layer={basis} position={"left"} changeLayer={handleChangeBasis} openLayerModal={null} playing={playing} setPlaying={null} loadingLayer={loadingLayer} map={map} showButton={isHistoryButtonActive} buttonActive={isClockAdFinished} loadingComparison={loadingComparison} timeTravelProgress={timeTravelProgress} /> }
            { isHistMode && histType === 'swipe' && <SmallLayerSwitcher layer={comparison} position={"right"} changeLayer={handleChangeComparison} openLayerModal={null} playing={playing} setPlaying={null} loadingLayer={loadingLayer} map={map} showButton={isHistoryButtonActive} buttonActive={isClockAdFinished} loadingComparison={loadingComparison} timeTravelProgress={timeTravelProgress} /> }
            { isHistMode && histType === 'fade' && isHistMode && <HistoryFader handleFadeChange={handleFadeChange}/>}
        </div >
    );
}


const SearchDescription = () => {

    const { t } = useTranslation();

    return <table style={{ borderSpacing: '1em' }}>
        <thead style={{ textAlign: 'left' }}>
            <tr>
                <th>{t('instruction_search_table_header_1')}</th>
                <th>{t('instruction_search_table_header_2')}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style={{ whiteSpace: 'nowrap' }}>
                    <code>
                        {t('instruction_search_table_code_1')}
                    </code>
                </td>
                <td>
                    <Trans i18nKey="instruction_search_table_description_1">

                    </Trans>
                </td>
            </tr>

            <tr>
                <td>
                    <code>
                        {t('instruction_search_table_code_2')}
                    </code>
                </td>
                <td>
                    <Trans i18nKey="instruction_search_table_description_2">

                    </Trans>
                </td>
            </tr>

            <tr>
                <td>
                    <code>
                        {t('instruction_search_table_code_3')}
                    </code>
                </td>
                <td>
                    <Trans i18nKey="instruction_search_table_description_3">
                    </Trans>
                </td>
            </tr>


        </tbody>

    </table>
}

export default MapView;