import React from "react";
import { styled } from "@mui/system";
import { Box, Typography, Icon, Grid } from "@mui/material";

const StyledKbd = styled('kbd')`
    box-shadow: 0.5px 0.5px 0 rgb(0 0 0 / 20%);
    border: 1px solid #ccc;
    margin: 0 4px;
    padding: 5px;
    border-radius: 3px;
    background: rgb(243, 243, 243);
    min-width: 30px;
    display: inline-flex;
    justify-content: center;
    min-height: 30px;
    `;

const MyKbd = ({ keys, label }) => {

    return <Grid container spacing={2} alignItems="center" sx={{ p: 1 }}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                {keys?.map((key, index) => (
                    <span key={index} style={{ display: 'inline-flex', alignItems: 'center', fontSize: '12px' }}>
                        {index > 0 && <Icon>add</Icon>}
                        {Array.isArray(key) ? (key.map((element,i) => (<span key={i} style={{ display: 'inline-flex', alignItems: 'center', fontSize: '12px' }}>
                            {i > 0 && <span style={{fontSize: '2em', fontWeight: 'bold', marginLeft: '6px', marginRight: '6px'}}>/</span>}
                            <StyledKbd sx={{ '& *': { fontSize: '12px' } }}>{element}</StyledKbd>
                        </span>))
                        ) : <StyledKbd sx={{ '& *': { fontSize: '12px' } }}>{key}</StyledKbd>}
                        
                    </span>
                ))}
            </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9} >
            <Typography variant="p" sx={{ marginLeft: t => t.spacing(2) }}>{label}</Typography>
        </Grid>
    </Grid>
}

export default MyKbd