import { useD3 } from './d3Hook';
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Grid, Box } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

import * as d3 from 'd3';
import { getLength } from 'ol/sphere';
import { LineString } from 'ol/geom';
import { useTranslation } from "react-i18next";

function HeightProfile({ heightData, closeFunction, setFeature, setVisibility, linePos }) {
  const { t } = useTranslation();
  const [position, setPosition] = useState(false);

  const data = [];
  let dist = 0;
  for (let i = 0, ii = heightData.heights.length; i < ii; ++i) {
      if (i > 0) {
          dist += getLength(new LineString(heightData.point_list.slice(i - 1, i + 1)), {projection: 'EPSG:4326'});
      }
      data.push({
          height: heightData.heights[i],
          dist,
          coordinates: heightData.point_list[i],
      });
  }
  
  const height = 200;
  const width = window.innerWidth < 600 ? 600 : window.innerWidth - 80;
  const margin = { top: 10, right: 10, bottom: 25, left: 50 };
  const x = d3.scaleLinear()
            .domain([d3.min(data.map((d) => d.dist)), d3.max(data.map((d) => d.dist))])
            .range([0, width-margin.right])

  const y = d3.scaleLinear()
    .domain([
        d3.min(data, (d) => d.height),
        d3.max(data, (d) => d.height)
    ])
    .range([height- margin.top - margin.bottom, 0]);

  const ticks = data.map(d => x(d.dist))

  const ref = useD3(
    (svg) => {

        svg = svg.html(null);
        svg = svg.attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform","translate(" + margin.left + "," + margin.top + ")");

        

        const xAxis = svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", `translate(0,${height-margin.bottom - margin.top})`)
            .call(d3.axisBottom(x))
            
        const yAxis = svg.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y))
        
        svg.append("text")
                .attr("fill", "#000")
                .attr("x", -(height + margin.top + margin.bottom)/2)
                .attr("y", 5 - margin.left)
                .attr("transform", "rotate(-90)")
                .attr("dy", "0.32em")
                .attr("text-anchor", "start")
                .style("fill", "red")
                //.attr("font-weight", "bold")
                .text("Meereshöhe [m]")   

        svg.append("text")
                .attr("fill", "#000")
                .attr("x", width/2)
                .attr("y", height)
                //.attr("transform", "rotate(-90)")
                .attr("dy", "0.32em")
                .attr("text-anchor", "center")
                .style("fill", "red")
                //.attr("font-weight", "bold")
                .text("Distanz [m]")
            
            
        d3.selectAll("g.yAxis g.tick")
            .append("line")
            .attr("class", "gridline")
            .attr("stroke", "blue")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", width-margin.right)
            .attr("y2", 0);
        

        d3.selectAll("g.xAxis g.tick")
            .append("line")
            .attr("class", "gridline")
            .attr("stroke", "blue")
            .attr("x1", 0)
            .attr("y1", -height)
            .attr("x2", 0)
            .attr("y2", 0);
            
        
        


        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "salmon")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function(d) { return x(d.dist) })
                .y(function(d) { return y(d.height) })
            )
      
        svg.append('g')
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", function (d) { return x(d.dist); } )
            .attr("cy", function (d) { return y(d.height); } )
            .attr("r", 3)
            .style("fill", "#69b3a2")

            
        let label = svg.append("g")
            .attr("transform", `translate(0,${-margin.bottom})`)
            .attr("id", "hoverRect")
            .attr("visibility", "hidden")
            
        let rec = label.append("rect")
            .attr("id", "hoverLine")
            .attr("height", height)
            .attr("fill", "red")
            .attr("width", 2)
            .attr("x", 120)
            
      
        svg.append("rect")
            .attr("fill", "none")
            .attr("width", width)
            .attr("height", height)
            .attr("pointer-events", "all")
            .on("mouseenter", function() { setFeature(null); setVisibility(true)})
            .on("mousemove", function(d) {
                label.attr("visibility", "visible"); 
                let mpos = d.offsetX - margin.left
                let ind = d3.minIndex(ticks.map(x => Math.abs(mpos-x)))
                rec.attr("x", ticks[ind]) 
                setFeature(data[ind])
                setPosition(data[ind])
            })
            .on("mouseleave", function() { 
              label.attr("visibility", "hidden");
              setVisibility(false)
              setFeature(null)
              setPosition(false)
            })

    },
    [heightData]
  );


  useEffect(() => {
    //const dist = Math.random()* 3000;
    let rect = d3.select(ref.current).select("g#hoverRect")
    if (linePos == null) {
        rect.attr("visibility", "hidden")
        return
    }
    let mapDist = x(linePos)
    rect.attr("visibility", "visible")
    let line = d3.select(ref.current).select("rect#hoverLine")
    let ind = d3.minIndex(ticks.map(t => Math.abs(t-mapDist)))
    line.attr("x", ticks[ind]);
    setPosition(data[ind]);
    setFeature(data[ind]);
  }, [linePos])


    return(
    <Paper
        className='height-profile'
        elevation={2}
        sx={{
            p: '2px 4px',
            position: 'fixed',
            bottom: (theme) => theme.spacing(1),
            left: (theme) => theme.spacing(1),
            width: 'calc(100% - 16px)',
            height: '340px',
            backgroundColor: '#fff',
            borderRadius: '0.5em',
            fontSize: '0.7em',
            padding: '0 0 0 0',
            zIndex: (theme) => theme.zIndex.drawer,
            border: t => `1px solid ${t.palette.primary.light}`,
            transition: 'width ease 250ms',
            boxSizing: 'border-box',
            transitionDelay: '250ms',
        }}
    >
        <Grid container sx={{ fontSize: '1rem', borderBottom: t => `1px solid ${t.palette.divider}` }}  >
            <Grid container item xs={8} sx={{ p: '10px' }}><Icon sx={{ mr: 2, color: '#666' }}>area_chart</Icon>{t("height_profile_title")} - {position.height} m</Grid>
            <Grid container item xs={4} justifyContent="flex-end">
                <IconButton onClick={closeFunction} sx={{ p: '10px 10px' }} ><Icon aria-label="toggle legend">close</Icon></IconButton>
            </Grid>
        </Grid>

        <Box
            sx={{
                p: 1,
                height: t => `calc(100% - ${t.spacing(12)})`,
                overflow: "hidden",
            }}
        >
            <svg ref={ref} />
        </Box>
        <Grid container sx={{ fontSize: '1rem', borderTop: t => `1px solid ${t.palette.divider}` }}  >
            <Grid container item xs={12} sx={{ p: '10px', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <Tooltip title="höchster Punkt">
                    <div style={{display: 'flex', alignItems: 'center', minWidth: 120}}>
                        <Icon sx={{ ml: 2, mr: 0.5, color: '#666' }}>vertical_align_top</Icon>
                        <span>{heightData.stats.highestPoint} m</span>
                    </div>
                </Tooltip>

                <Tooltip title="niedrigster Punkt">
                    <div style={{display: 'flex', alignItems: 'center', minWidth: 120}}>
                        <Icon sx={{ ml: 2, mr: 0.5, color: '#666' }}>vertical_align_bottom</Icon>
                        <span>{heightData.stats.lowestPoint} m</span>
                    </div>
                </Tooltip>

                <Tooltip title="Höffendifferenz Maximun - Minimum">
                <div style={{display: 'flex', alignItems: 'center', minWidth: 120}}>
                        <Icon sx={{ ml: 2, mr: 0.5, color: '#666' }}>height</Icon>
                        <span>{heightData.stats.heightDiffMinMax} m</span>
                    </div>
                </Tooltip>

                <Tooltip title="Höhendifferenz Start - Ende">
                    <div style={{display: 'flex', alignItems: 'center', minWidth: 120}}>
                        <Icon sx={{ ml: 2, mr: 0.5, color: '#666' }}>swap_vert</Icon>
                        <span>{heightData.stats.heightDiffStartEnd} m</span>
                    </div>
                </Tooltip>

                <Tooltip title="Summe Aufstiege">
                    <div style={{display: 'flex', alignItems: 'center', minWidth: 120}}>
                        <Icon sx={{ ml: 2, mr: -1, color: '#666' }}>functions</Icon>
                        <Icon sx={{ mr: 0.5, color: '#666' }}>north</Icon>
                        <span>{heightData.stats.sumUps} m</span>
                    </div>
                </Tooltip>



                <Tooltip title="Summe Abstiege">
                    <div style={{display: 'flex', alignItems: 'center', minWidth: 120}}>
                        <Icon sx={{ ml: 2, mr: -1, color: '#666' }}>functions</Icon>
                        <Icon sx={{ mr: 0.5, color: '#666' }}>south</Icon>
                        <span>{heightData.stats.sumDowns} m</span>
                    </div>
                </Tooltip>


                <Tooltip title="Distanz">
                    <div style={{display: 'flex', alignItems: 'center', minWidth: 120}}>
                        <Icon sx={{ ml: 2, mr: 0.5, color: '#666' }}>signpost</Icon>
                        <span>{heightData.stats.dist} m</span>
                    </div>
                </Tooltip>
            </Grid>
        </Grid>
    </Paper>)

}

export default HeightProfile;
