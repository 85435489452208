//TODO Replace these static image url exports with the correct URL templates
/*
import {default as EPO_1_URL_} from 'assets/img/01_3LA_1873.jpg';
 import {default as EPO_2_URL_} from 'assets/img/02_SK_1915.jpg';
 import {default as EPO_3_URL_} from 'assets/img/03_SK_1937.jpg';
 import {default as EPO_4_URL_} from 'assets/img/04_OEK50_prov_1963.jpg';
 import {default as EPO_5_URL_} from 'assets/img/05_OEK50_1985.jpg';
 import {default as EPO_6_URL_} from 'assets/img/06_OEK50_1997.jpg';
 import {default as EPO_7_URL_} from 'assets/img/07_OEK50_2008.jpg';
 export const EPO_1_URL = EPO_1_URL_ + '?{z}/{x}/{y}';
 export const EPO_2_URL = EPO_2_URL_ + '?{z}/{x}/{y}';
 export const EPO_3_URL = EPO_3_URL_ + '?{z}/{x}/{y}';
 export const EPO_4_URL = EPO_4_URL_ + '?{z}/{x}/{y}';
 export const EPO_5_URL = EPO_5_URL_ + '?{z}/{x}/{y}';
 export const EPO_6_URL = EPO_6_URL_ + '?{z}/{x}/{y}';
 export const EPO_7_URL = EPO_7_URL_ + '?{z}/{x}/{y}';

import('../../common/map/layers').then(({karte}) => karte.getSource().setTileUrlFunction((coord) => {
     const z = coord[0].toString().padStart(2,0)
     let x = coord[1].toString().padStart(9,0)
     let y = coord[2].toString().padStart(9,0)
     x = x.substr(0,3) + '/' + x.substr(3,3) + '/' + x.substr(6,3)
     y = y.substr(0,3) + '/' + y.substr(3,3) + '/' + y.substr(6,3)
     return 'https://maps.bev.gv.at/tiles/' + z + '/' + x + '/' + y + '.png' 
 }));
*/

export const TIMETRAVEL_FRAME_DURATION = 5000;
export const PLAY_LOOP = ['epo_1', 'epo_2', 'epo_3', 'epo_4', 'epo_5', 'epo_6', 'epo_7', 'karte'];

export const MAP_URL = process.env.REACT_APP_MAP_URL ? process.env.REACT_APP_MAP_URL : 'https://maps.bev.gv.at/tiles/'


export const MAPS_URL  = 'https://maps.bev.gv.at/tiles/karte/{z}/{x}/{y}.png'
export const EPO_1_URL = MAP_URL + 'EPO1/{z}/{x}/{y}.png';
export const EPO_2_URL = MAP_URL + 'EPO2/{z}/{x}/{y}.png';
export const EPO_3_URL = MAP_URL + 'EPO3/{z}/{x}/{y}.png';
export const EPO_4_URL = MAP_URL + 'EPO4/{z}/{x}/{y}.png';
export const EPO_5_URL = MAP_URL + 'EPO5/{z}/{x}/{y}.png';
export const EPO_6_URL = MAP_URL + 'EPO6/{z}/{x}/{y}.png';
export const EPO_7_URL = MAP_URL + 'EPO7/{z}/{x}/{y}.png';
export {default as CLIP_URL} from 'assets/at_clip.geojson';

export const basic_get_options = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};

export const basic_post_options = {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
}

export const numberWithPoints = (x) => {
    return x.toLocaleString('de-AT');
}

export const searchUrl = process.env.REACT_APP_OVERRIDE_SEARCH_URL || 'https://kataster.bev.gv.at/' + 'api/all4Map/'

export const watchPositionOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

export const DIMS = {
    //a3: [420, 297],
    a3: [400, 255],
    //a4: [297, 210],
    a4: [277, 168],
    //a3P: [297, 420],
    a3P: [277, 378],
    //a4P: [210, 297],
    a4P: [190, 255],
  
  };

		
		
		/*
Sonstige Name	58 / 170 / 53	3aaa35
*/


// export const featureTypes = {
//     1: { short: 'BL', long: 'constants_featureTypes_1', color: 'purple' },
//     2: { short: 'PB', long: 'constants_featureTypes_2', color: 'purple' },
//     3: { short: 'PG', long: 'constants_featureTypes_3', color: 'red' },
//     4: { short: 'KG', long: 'constants_featureTypes_4', color: 'orange' },
//     5: { short: 'GST', long: 'constants_featureTypes_5', color: 'grey' },
//     6: { short: 'GP', long: 'constants_featureTypes_6', color: 'red' },
//     7: { short: 'NU', long: 'constants_featureTypes_7', color: 'lime' },
//     8: { short: 'EZ', long: 'constants_featureTypes_8', color: 'black' },
//     11: { short: 'ADR', long: 'constants_featureTypes_11', color: '#ff8800' },
//     12: { short: 'STR', long: 'constants_featureTypes_12', color: 'purple' },
//     21: { short: 'FP', long: 'constants_featureTypes_21', color: 'RoyalBlue' },
//     101: { short: 'CRD', long: 'constants_featureTypes_101', color: 'navy' },
//     102: { short: 'CRD', long: 'constants_featureTypes_102', color: 'navy' },
//     103: { short: 'CRD', long: 'constants_featureTypes_103', color: 'navy' },
//     104: { short: 'CRD', long: 'constants_featureTypes_104', color: 'navy' },
//     105: { short: 'CRD', long: 'constants_featureTypes_105', color: 'navy' },
//     106: { short: 'CRD', long: 'constants_featureTypes_106', color: 'navy' },
//     7101: { short: 'GEO_SIEDLUNG', long: 'constants_featureTypes_7101', color: '#946100' },
//     7102: { short: 'GEO_SIEDLUNG', long: 'constants_featureTypes_7102', color: '#946100' },
//     7103: { short: 'GEO_SIEDLUNG', long: 'constants_featureTypes_7103', color: '#946100' },
//     7104: { short: 'GEO_SIEDLUNG', long: 'constants_featureTypes_7104', color: '#946100' },
//     7111: { short: 'GEO_SIEDLUNG', long: 'constants_featureTypes_7111', color: '#ee6d2e' },
//     7123: { short: 'GEO_SIEDLUNG', long: 'constants_featureTypes_7123', color: '#946100' },
//     7124: { short: 'GEO_SIEDLUNG', long: 'constants_featureTypes_7124', color: '#946100' },
//     7131: { short: 'GEO_SIEDLUNG', long: 'constants_featureTypes_7131', color: '#ee6d2e' },
//     7201: { short: 'GEO_GEBIET', long: 'constants_featureTypes_7201', color: '#0062a7' },
//     7202: { short: 'GEO_GEBIET', long: 'constants_featureTypes_7202', color: '#0062a7' },
//     7203: { short: 'GEO_GEBIET', long: 'constants_featureTypes_7203', color: '#0062a7' },
//     7204: { short: 'GEO_GEBIET', long: 'constants_featureTypes_7204', color: '#0062a7' },
//     7301: { short: 'GEO_BERG', long: 'constants_featureTypes_7301', color: '#555555' },
//     7302: { short: 'GEO_BERG', long: 'constants_featureTypes_7302', color: '#555555' },
//     7303: { short: 'GEO_BERG', long: 'constants_featureTypes_7303', color: '#555555' },
//     7304: { short: 'GEO_BERG', long: 'constants_featureTypes_7304', color: '#555555' },
//     7305: { short: 'GEO_BERG', long: 'constants_featureTypes_7305', color: '#555555' },
//     7401: { short: 'GEO_GLETSCHER', long: 'constants_featureTypes_7401', color: '#009fe3' },
//     7402: { short: 'GEO_GLETSCHER', long: 'constants_featureTypes_7402', color: '#009fe3' },
//     7403: { short: 'GEO_GLETSCHER', long: 'constants_featureTypes_7403', color: '#009fe3' },
//     7501: { short: 'GEO_WASSER', long: 'constants_featureTypes_7501', color: '#009fe3' },
//     7502: { short: 'GEO_WASSER', long: 'constants_featureTypes_7502', color: '#009fe3' },
//     7503: { short: 'GEO_WASSER', long: 'constants_featureTypes_7503', color: '#009fe3' },
//     7504: { short: 'GEO_WASSER', long: 'constants_featureTypes_7504', color: '#009fe3' },
//     7511: { short: 'GEO_WASSER', long: 'constants_featureTypes_7511', color: '#009fe3' },
//     7512: { short: 'GEO_WASSER', long: 'constants_featureTypes_7512', color: '#009fe3' },
//     7513: { short: 'GEO_WASSER', long: 'constants_featureTypes_7513', color: '#009fe3' },
//     7521: { short: 'GEO_WASSER', long: 'constants_featureTypes_7521', color: '#009fe3' },
//     7611: { short: 'GEO_SONSTIGE', long: 'constants_featureTypes_7611', color: '#3aaa35' },
//     7701: { short: 'RN', long: 'constants_featureTypes_7701', color: 'darkgreen' },
// }

export const LINIEN_LEGENDE = {
    1: { color: "purple", description: "constants_linien_7" },
    2: { color: "red", description: "constants_linien_6" },
    3: { color: "orange", description: "constants_linien_5" },
    4: { color: "#000000", description: "constants_linien_1" },
    5: { color: "#ff0000", description: "constants_linien_3" },
    6: { color: "#00ff00", description: "constants_linien_4" },
    7: { color: "#3399ff", description: "constants_linien_2" }
}

export const LAYER_PRIORITY = {
    bl: 10,
    pb: 9,
    pg: 8,
    kg: 7,
    gst: 6,
    ez: 5,
    nu: 4,
    gp: 3,
    adr: 2,
    str: 1,
    geo_siedlung: 0,
    geo_gebiet: -1,
    geo_berg: -2,
    geo_gletscher: -3,
    geo_wasser: -4,
    geo_sonstige: -5,
    rn: -6,
    fp: -7,
}

export const newLegendSVG = {
    triggers: {
        0: 'KM1000',
        9.5: 'KM500',
        11.5 : 'KM250',
        13.5 : 'KM50R'
    },
    titles: {
        KM50R: '1:50.000',
        KM250: '1:250.000',
        KM500: '1:500.000',
        KM1000: '1:1.000.000'
    },
    prefixes: {
        KM50R: 'KM50',
        KM250: 'KM250',
        KM500: 'KM500',
        KM1000: 'KM1000'
    },
    translation: {
        Verkehr: 'constants_legend_1',
        Einzelobjekte: 'constants_legend_2',
        Gewässer: 'constants_legend_3',
        Vegetation: 'constants_legend_4',
        Geländedarstellung: 'constants_legend_5',
        Namen: 'constants_legend_6',
        Grenzen: 'constants_legend_7',
        Thematischer_Aufdruck: 'constants_legend_8',
        Wegmarkierungen: 'constants_legend_9',
        Abkürzungen: 'constants_legend_10',
        Siedlungsdarstellung: 'constants_legend_11',
        Flugverkehr: 'constants_legend_12',
        Gesamt: 'constants_legend_13'
    },
    categories: {
        KM50R: [
            'Verkehr', 
            'Einzelobjekte', 
            'Gewässer', 
            'Vegetation', 
            'Geländedarstellung', 
            'Namen', 
            'Grenzen', 
            'Thematischer_Aufdruck',
            'Wegmarkierungen',
            'Abkürzungen', 
        ],
        KM250: [
            'Verkehr',
            'Einzelobjekte', 
            'Gewässer', 
            'Vegetation', 
            'Geländedarstellung', 
            'Siedlungsdarstellung', 
            'Flugverkehr', 
            'Namen', 
            'Grenzen', 
            'Thematischer_Aufdruck'
        ],
        KM500: [
            'Verkehr',
            'Einzelobjekte', 
            'Gewässer', 
            'Vegetation', 
            'Siedlungsdarstellung', 
            'Namen', 
            'Grenzen', 
        ],
        KM1000: [
            'Gesamt'
        ]
    }
}
