import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {json as de} from './de';
import {json as en} from './en';

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    // we init with resources
    resources: {
        de: de,
        en: en
    },
    fallbackLng : "de",
    debug : process.env.NODE_ENV === 'development',

    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false
    }
}).then(() => {
    console.log('i18n initialised');
});

export default i18n;
