import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Dialog, DialogContent, Slide, IconButton, Icon, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ImprintDialog = ({ open, closeFunction }) => {
    const { t } = useTranslation();

    return <Dialog open={open}
        fullWidth
        maxWidth="lg"
        onClose={closeFunction}
        TransitionComponent={Transition}
        keepMounted>
        <IconButton
            aria-label="close"
            onClick={closeFunction}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <Icon>close</Icon>
        </IconButton>
        <DialogContent>
        <h3 style={{ color: "#d00" }}>{t('impressum_header_imprint')}</h3>
            <address style={{ fontStyle: 'normal' }}>
                <strong>{t('impressum_organisation')}</strong>
                <br />
                {t('impressum_street')}<br />
                {t('impressum_post_code')}<br />
                <br />
                <a href="tel:+431211100" style={{ color: 'black', textDecoration: 'none' }}>{t('impressum_tel')}</a>
                <br />
                <a href="http://bev.gv.at" rel="noreferrer">bev.gv.at</a><br />
                <a href="mailto:kundenservice@bev.gv.at">kundenservice@bev.gv.at</a><br />
                <br />
                <Trans i18nKey="impressum_contact_portal">
                    Das <a href="https://www.bev.gv.at/Das-BEV/Kontakte-Standorte.html" rel="noreferrer">Kontaktportal</a> des BEV
                </Trans><br />
                <br />

                <strong>{t('impressum_userservice')}</strong><br />
                <br />
                <a href="tel:+43121110822160" style={{ color: 'black', textDecoration: 'none' }}>{t('impressum_tel_contact')}</a><br />
                <a href="mailto:kundenservice@bev.gv.at">kundenservice@bev.gv.at</a><br />
                <br />
                {t('impressum_vat_number')}<br />
                <br />
                {t('impressum_bank')}<br />
                {t('impressum_iban')}<br />
                {t('impressum_bic')}<br />
                <br />
                {t('impressum_regulatory_body')}<br />
            </address>

            <h3 style={{ color: "#d00" }}>{t("impressum_header_one")}</h3>
            <p>
                <Trans i18nKey="impressum_paragraph_1">
                    Alle Angaben (Berechnungsergebnisse) sind trotz sorgfältigster Bearbeitung/Verarbeitung ohne Gewähr und nicht rechtsverbindlich. Eine Haftung des BEV für die auf <a href="/" >maps.bev.gv.at</a> angezeigten und abgerufenen Inhalte wird ausgeschlossen.
                </Trans> 
            </p>
            <Typography variant="p" sx={{ whiteSpace: 'pre-line', p: 2, display: 'inline-block' }}>
            <Trans i18nKey="impressum_paragraph_1_1">
            Sämtliche Inhalte stehen Ihnen unter Einhaltung folgender Lizenzbedingunen zur Verfügung:

            Creative Commons CC BY 4.0:
            <a href="https://creativecommons.org/licenses/by/4.0/legalcode.de">Creative Commons — Namensnennung 4.0 International — CC BY 4.0</a>

            Einen kurzen Überblick über diese Creative Commons Lizenz finden Sie hier:
            <a href="https://creativecommons.org/licenses/by/4.0/deed.de">Creative Commons — Namensnennung 4.0 International — CC BY 4.0</a>

            Weitere Informatioen Zu BEV Produkten und deren Lizenzen finden sie <a href="https://www.bev.gv.at/Services.html">hier</a>.

            </Trans>
            </Typography>
            <h3 style={{ color: "#d00" }}>{t("impressum_header_two")}</h3>
            <p>
                <Trans i18nKey="impressum_paragraph_2">
                    Die <a href="https://www.bev.gv.at/Rechtliches/Datenschutzerklaerung.html" rel="noreferrer">Datenschutzerklärung</a> des BEV.
                </Trans>
            </p>

            <h3 style={{ color: "#d00" }}>{t("impressum_header_three")}</h3>
            <p>{t("impressum_paragraph_3")}</p>
            <p>
                <Trans i18nKey="impressum_paragraph_4">
                    Diese Erklärung zur Barrierefreiheit gilt für die Website <a href="/">maps.bev.gv.at.</a>
                </Trans>
            </p>

            <h4 style={{ color: "#333" }}>{t("impressum_header_four")}</h4>
            <p>{t("impressum_paragraph_5")}</p>
            <p>{t("impressum_paragraph_6")}</p>
            <p>
                <Trans i18nKey="impressum_paragraph_7">
                    Es handelt sich bei <a href="/">maps.bev.gv.at</a> um einen reinen Darstellungsdienst.
                </Trans>
            </p>

            <h4 style={{ color: "#333" }}>{t("impressum_header_five")}</h4>
            <p>{t("impressum_paragraph_8")}</p>

            <h4 style={{ color: "#333" }}>{t("impressum_header_six")}</h4>
            <p>
                <Trans i18nKey="impressum_paragraph_9">
                    Sämtliche Mitteilungen und Anregungen senden Sie uns bitte an <a href="mailto:kundenservice@bev.gv.at" rel="noreferrer">kundenservice@bev.gv.at.</a>
                </Trans>
            </p>
            <p>{t("impressum_paragraph_10")}</p>
            <p>{t("impressum_paragraph_11")}</p>
            <p>{t("impressum_paragraph_12")}</p>
            <p>
                <Trans i18nKey="impressum_paragraph_13">
                    E-Mail: <a href="mailto:kundenservice@bev.gv.at" rel="noreferrer">kundenservice@bev.gv.at</a>
                </Trans>
            </p>

            <h4 style={{ color: "#333" }}>{t("impressum_header_seven")}</h4>
            <p>{t("impressum_paragraph_14")}</p>
            <p><a href="https://www.ffg.at/form/kontaktformular-beschwerdestelle" rel="noreferrer">{t("impressum_paragraph_15")}</a></p>
            <p>{t("impressum_paragraph_16")}</p>
            <p>{t("impressum_paragraph_17")}</p>
            <p><a href="https://www.ffg.at/digitale-barrierefreiheit/digitales-zugaenglich-machen/beschwerdestelle" rel="noreferrer">{t("impressum_paragraph_18")}</a></p>

        </DialogContent>
    </Dialog>
}

export default ImprintDialog;

