import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Grid, Box } from "@mui/material";
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { BrowserRouter, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const RecordStories = ({ visible, level, map, openFunction, maxFunction, closeFunction }) => {
    const { t } = useTranslation();

    if (!visible) return null;

    if (level === 0) {
        return (
            <Paper
            elevation={2}
            sx={{
                position: 'fixed',
                top: (theme) => level < 2 ? `calc(143px + ${theme.spacing(13)})` : `calc(143px + ${theme.spacing(2)})`,
                left: (theme) => theme.spacing(2),
                zIndex: (theme) => theme.zIndex.drawer - 4,
                flexDirection: 'column',
                borderRadius: '0.5em',
                display: 'flex',
            }}
            >
            <ButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em' }}
                orientation="vertical"
                aria-label="vertical contained button group"
                variant="text"
                color="neutral"
            >
                <Button sx={{ p: { xs: 1, md: 1} }} title={t("icon_erfassung")} aria-label="open layers selection" onClick={openFunction}><Icon>quiz</Icon></Button>
            </ButtonGroup>
        </Paper>
        )
    }

    if (level > 0) {
        return (
            <BrowserRouter>
                <Paper
                    elevation={2}
                    sx={{
                        p: '2px 4px',
                        position: 'fixed',
                        top: (theme) => level < 2 ? `calc(143px + ${theme.spacing(13)})` : theme.spacing(4),
                        left: (theme) => ({
                            xs: theme.spacing(2),
                            lg: level < 2 ? theme.spacing(2) : 'calc(50vw - 600px)',

                        }),
                        width: (theme) => ({
                            xs: `calc(100% - ${theme.spacing(4)})`,
                            md: level < 2 ? '400px' : `calc(100vw - ${theme.spacing(4)})`,
                            lg: level < 2 ? '400px' : '1200px',
                        }),
                        maxHeight: (theme) => level < 2 ? `calc(100vh - ${theme.spacing(39)})` : `calc(100vh - ${theme.spacing(8)})`,
                        backgroundColor: '#fff',
                        borderRadius: '0.5em',
                        fontSize: '1em',
                        padding: '0 0 0 0',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        border: t => `1px solid ${t.palette.primary.light}`,
                        transition: 'width ease 250ms',
                        boxSizing: 'border-box',
                        transitionDelay: '250ms',
                    }}
                >
                    <Grid container sx={{ fontSize: '1rem', borderBottom: t => `1px solid ${t.palette.divider}` }}  >
                        <Grid container item xs={1}>
                            <IconButton onClick={closeFunction} sx={{ pl: '14px' }} ><Icon title={t('icon_close_menu')} aria-label="toggle legend">close</Icon></IconButton>
                        </Grid>
                        <Grid container item xs={9} sx={{ pl: '16px', py: '10px' }}>{t('rec_hist_title')}</Grid>
                        <Grid container item xs={2} justifyContent="flex-end">
                        <Icon title="{t('rec_hist_title')}" sx={{ m: '10px', mr: '14px', color: '#666' }}>quiz</Icon>
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            maxHeight: (theme) => `calc(100vh - ${theme.spacing(18 + (2 - level) * 26)})`,
                            overflow: 'hidden',
                            overflowY: "auto",
                            px: 2,
                            borderRadius: '0.5em'
                        }}
                    >
                        <Box dangerouslySetInnerHTML={{__html: t('rec_hist_' + map + '_small')}} />
                        {level === 2 && (
                            <Box>
                                <div dangerouslySetInnerHTML={{__html: t('rec_hist_' + map + '_full')}} />
                                <Grid container spacing={4} sx={{ mt: '20px'}} >
                                    <Grid item xs={12} md={6}>
                                        <Link to={'/images/Epoche_0' + map.substring(4,5) + '_01_gross.jpg'} target='_blank'>
                                            <img style={{ width: '100%', objectFit: 'contain'}} alt={`${t('rec_hist_title')}, ${t('rec_hist_image')} 1`} src={'/images/Epoche_0' + map.substring(4,5) + '_01_klein.jpg'} />
                                        </Link>
                                        <p style={{ textAlign: 'center', fontSize: '0.8em', fontStyle: 'italic'}}>{t('rec_hist_' + map + '_img1')}</p>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Link to={'/images/Epoche_0' + map.substring(4,5) + '_02_gross.jpg'} target='_blank'>
                                            <img style={{ width: '100%', objectFit: 'contain'}} alt={`${t('rec_hist_title')}, ${t('rec_hist_image')} 2`} src={'/images/Epoche_0' + map.substring(4,5) + '_02_klein.jpg'} />
                                        </Link>
                                        <p style={{ textAlign: 'center', fontSize: '0.8em', fontStyle: 'italic'}}>{t('rec_hist_' + map + '_img2')}</p>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        {level === 1 && (<Button sx={{ float: 'right', marginRight: 2, marginBottom: 2 }} variant='text' onClick={maxFunction}>{t("rec_hist_link_more")}</Button>)}
                    </Box>
                </Paper>
                </BrowserRouter>
        )
    }
}

export default RecordStories;

