import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import { useTranslation } from "react-i18next";

const MapControlls = ({
    flyOutOpen, openFlyoutSketch, closeFlyoutSketch,
    flyOutExportOpen, openFlyoutExport, closeFlyoutExport,
    flyOutImportOpen, openFlyoutImport, closeFlyoutImport
}) => {
    const { t } = useTranslation();

    return (
        <Paper
            elevation={2}
            sx={{
                position: 'fixed',
                top: theme => `calc(${theme.spacing(6)} + 70px)`,
                right: (theme) => theme.spacing(2),
                zIndex: (theme) => theme.zIndex.drawer - 3,
                flexDirection: 'column',
                borderRadius: '0.5em',
                borderTopLeftRadius: flyOutOpen ? 0 : '0.5em',
                borderBottomLeftRadius: flyOutExportOpen ? 0 : '0.5em',
                display: 'flex',
            }}
        >
            <ButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em' }}
                orientation="vertical"
                aria-label="vertical contained button group"
                variant="text"
                color="neutral"
            >
                <SwitchButton open={flyOutOpen} bottomLine={flyOutImportOpen} title={t('icon_draw')} openHandler={openFlyoutSketch} closeHandler={closeFlyoutSketch} icon="draw" />
                <SwitchButton open={flyOutImportOpen} bottomLine={flyOutExportOpen} title={t('icon_import')} openHandler={openFlyoutImport} closeHandler={closeFlyoutImport} icon="upload" />
                <SwitchButton open={flyOutExportOpen} title={t('icon_download')} openHandler={openFlyoutExport} closeHandler={closeFlyoutExport} icon="download" />

            </ButtonGroup>
        </Paper>
    );
}

export default MapControlls

const SwitchButton = ({ open, openHandler, closeHandler, icon, title, bottomLine }) => {
    const { t } = useTranslation();
    if (!open) {
        return (<Button sx={{
            p: { xs: 1, md: 1 },
            '&.MuiButtonGroup-grouped:not(:last-of-type)': {
                borderBottomColor: t => bottomLine ? 'transparent' : t.palette.divider
            }
        }} title={title} onClick={openHandler} >
            <Icon>{icon}</Icon>
        </Button>)
    } else {
        return (<Button sx={{
            p: { xs: 1, md: 1 },
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            '&.MuiButtonGroup-grouped': {
                border: t => `solid 1px ${t.palette.primary.light}`,
                borderLeft: t => t.palette.divider
            },
            '&.MuiButtonGroup-grouped:not(:last-of-type)': {
                border: t => `solid 1px ${t.palette.primary.light}`,
                borderLeft: t => t.palette.divider
            }
        }} title={t('icon_close_menu')} onClick={closeHandler} >
            <Icon>close</Icon>
        </Button>)
    }
}