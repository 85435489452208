import { Feature, Overlay } from 'ol';
import View from 'ol/View.js';
import { fromLonLat } from "ol/proj"; 
import { LineString, Point } from 'ol/geom';
import { TileWMS, Vector } from 'ol/source';
import XYZ from 'ol/source/XYZ.js';
import TileLayer from 'ol/layer/Tile.js';
import VectorLayer from 'ol/layer/Vector';
import { Style, Text, Fill, Stroke, Circle } from 'ol/style';
import { createXYZ } from 'ol/tilegrid';
import { CLIP_URL, EPO_1_URL, EPO_2_URL, EPO_3_URL, EPO_4_URL, EPO_5_URL, EPO_6_URL, EPO_7_URL, MAPS_URL } from 'common/constants';
import { getRenderPixel } from 'ol/render';
import VectorSource from 'ol/source/Vector';
import { Modify } from 'ol/interaction';
import { unByKey } from 'ol/Observable';
import { equals } from 'ol/coordinate';

const layersToClip = [];

export const orthoSource = new TileWMS({
  // minZoom: 8,
  url: 'https://kataster.bev.gv.at/ortho/ows',
  projection: 'EPSG:31287',
  crossOrigin: 'anonymous',
  params: {
    'LAYERS': 'inspire:AT_BEV_OI',
    'FORMAT': 'image/jpeg',
  },
  //serverType: 'geoserver',
  tileGrid: createXYZ({tileSize: [512, 512]}),

});

const emptyImageUrl = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
export const basisSource = new XYZ({
  crossOrigin: 'anonymous',
  maxZoom: 18,
  url: MAPS_URL,
  tileLoadFunction: (imageTile, src) => {
    const image = imageTile.getImage();
    let retries = 0;
    const doFetch = () => fetch(src, {mode: 'cors'}).then((response) => {
      if (response.status === 500 && retries < 4) {
        ++retries;
        setTimeout(doFetch, Math.pow(2, retries) * 1000);
        return;
      }
      if (response.ok) {
        response.blob().then((blob) => {
          const objectURL = URL.createObjectURL(blob);
          image.addEventListener('load', function onload() {
            URL.revokeObjectURL(objectURL);
            image.removeEventListener('load', onload);
          });
          image.src = objectURL;
        }).catch(() => {
          image.src = emptyImageUrl;
        });
        return;
      }
      image.src = emptyImageUrl;
    }).catch(() => {
      image.src = emptyImageUrl;
    });
    doFetch();
  },
  zDirection: (value, high, low) => {
    return value - low * Math.sqrt(high / low);
  }
});
export const basisLayer = new TileLayer({
  source: basisSource
});
layersToClip.push(basisLayer);

export const comparisonSource = new XYZ({
  crossOrigin: 'anonymous',
  maxZoom: 18,
  url: MAPS_URL,
});
export const comparisonLayer = new TileLayer({
  source: comparisonSource,
  visible: false
});
layersToClip.push(comparisonLayer);

const tileGrid_0_18 = createXYZ({maxZoom: 18});
const tileGrid_9_14 = createXYZ({minZoom: 9, maxZoom: 14});
const tileGrid_9_16 = createXYZ({minZoom: 9, maxZoom: 16});

export const layerDefinitions = {
  epo_1: {url: EPO_1_URL, tileGrid: tileGrid_9_16},
  epo_2: {url: EPO_2_URL, tileGrid: tileGrid_9_14},
  epo_3: {url: EPO_3_URL, tileGrid: tileGrid_9_14},
  epo_4: {url: EPO_4_URL, tileGreid: tileGrid_9_14},
  epo_5: {url: EPO_5_URL, tileGrid: tileGrid_9_14},
  epo_6: {url: EPO_6_URL, tilerGrid: tileGrid_9_14},
  epo_7: {url: EPO_7_URL, tileGrid: tileGrid_9_14},
  karte: {url: MAPS_URL, tileGrid: tileGrid_0_18},
  ortho: {source: orthoSource}
}

// Clip layers to Austria
// const clipSource = new VectorSource();
// const clipStyle = new Style({
//   fill: new Fill({
//     color: 'black',
//   }),
// });
// function clipPostrender(e) {
//   const vectorContext = getVectorContext(e);
//   e.context.globalCompositeOperation = 'destination-in';
//   clipSource.forEachFeature((feature) => vectorContext.drawFeature(feature, clipStyle));
//   e.context.globalCompositeOperation = 'source-over';
// }
// fetch(CLIP_URL).then((response) => response.json()).then((json) => {
//   clipSource.addFeatures(new GeoJSON().readFeatures(json, {featureProjection: 'EPSG:3857'}));
//   const clipExtent = clipSource.getExtent();
//   layersToClip.forEach((layer) => {
//     layer.setExtent(clipExtent);
//     layer.on('postrender', clipPostrender);
//   });
// });

const sliderPrerender = (fromFraction, toFraction, event) => {
  const ctx = event.context;
  const mapSize = event.frameState.size;
  const fromPx = mapSize[0] * fromFraction();
  const toPx = mapSize[0] * toFraction();
  const tl = getRenderPixel(event, [fromPx, 0]);
  const tr = getRenderPixel(event, [toPx, 0]);
  const bl = getRenderPixel(event, [fromPx, mapSize[1]]);
  const br = getRenderPixel(event, [toPx, mapSize[1]]);

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(tl[0], tl[1]);
  ctx.lineTo(bl[0], bl[1]);
  ctx.lineTo(br[0], br[1]);
  ctx.lineTo(tr[0], tr[1]);
  ctx.closePath();
  ctx.clip();
};

const sliderPostrender = (event) => {
  const ctx = event.context;
  ctx.restore();
}

let sliderPrerenderKeyBasis = undefined;
let sliderPrerenderKeyComparison = undefined;
/**
* @param {boolean} histMode 
* @param {import("react").MutableRefObject<number>} sliderValue
*/
export const updateSlider = (histMode, sliderValue) => {
  if (sliderPrerenderKeyBasis) {
    unByKey(sliderPrerenderKeyBasis);
    sliderPrerenderKeyBasis = undefined;
    basisLayer.un('postrender', sliderPostrender);
  }
  if (sliderPrerenderKeyComparison) {
    unByKey(sliderPrerenderKeyComparison);
    sliderPrerenderKeyComparison = undefined;
    comparisonLayer.un('postrender', sliderPostrender);
  }

  if (histMode) {
    sliderPrerenderKeyBasis = basisLayer.on('prerender', (event) => sliderPrerender(() => 0, () => sliderValue.current, event));
    basisLayer.on('postrender', sliderPostrender);
    sliderPrerenderKeyComparison = comparisonLayer.on('prerender', (event) => sliderPrerender(() => sliderValue.current, () => 1, event));
    comparisonLayer.on('postrender', sliderPostrender);}
}

// Creates a feature with geometry Point and label, from Coordinates (LonLat or Makator)
export const generatePointerFeatureFromLonLat = (position, label) => generatePointerFeature(fromLonLat(position), label);
export const generatePointerFeature = (position, label) => new Feature({
  geometry: new Point(position),
  name: label
});

// creates a Vector layer with an Marker style for way points or POI etc.
export const markerLayer = (iconFeature, icon) => {
  return new VectorLayer({
    source: new Vector({
      features: [iconFeature]
    }),
    style: styleFunctionMarker(icon),
    zIndex: 17
  })
}

// Layer with style for the center Marker
export const centerLayer = (iconFeature) => new VectorLayer({
  source: new Vector({
    crossOrigin: 'anonymous',
    features: [iconFeature]
  }),
  style: new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(0,0,0,1)',
      }),
      stroke: new Stroke({
        color: 'rgba(0,0,0,0.4)',
        width: 4,
      }),
      radius: 1,
    }),
    fill: new Fill({
      color: 'rgba(255,255,255,0.4)',
    }),
    stroke: new Stroke({
      color: '#3399CC',
      width: 1.25,
    }),
  }),
  zIndex: 15
})

/**
 * A layer with a modify interaction to slide between base and comparison layer
 * @param {import("ol").Map} map
 * @param {import("react").MutableRefObject<number>} sliderValue
 */
export const comparisonSlider = (map, sliderValue) => {
  const point = new Point([NaN, NaN]);
  let pixel;
  map.on('change:size', ({oldValue}) => {
    const size = map.getSize();
    if (pixel) {
      pixel[0] -= (oldValue[0] - size[0]) / 2;
      const minX = 10;
      const minY = 10;
      const maxX = size[0] - 10;
      const maxY = size[1] - 10;
      pixel = [
        Math.max(Math.min(pixel[0], maxX), minX),
        Math.max(Math.min(pixel[1], maxY), minY)
      ];
      point.setCoordinates(map.getCoordinateFromPixel(pixel));
    }
  });
  point.on('change', () => {
    const [x] = point.getCoordinates();
    const resolution = map.getView().getResolution();
    const [width] = map.getSize();
    const left = map.getView().calculateExtent(map.getSize())[0];
    sliderValue.current = (x - left) / resolution / width;
  });
  const feature = new Feature(point);
  const source = new VectorSource({
    features: [feature]
  });
  const modify = new Modify({
    hitDetection: true,
    source,
    style: () => {}
  });
  let modifying = false;
  modify.on('modifystart', () => modifying = true);
  modify.on('modifyend', (event) => {
    modifying = false;
    const sliderGeometry = event.features.item(0).getGeometry();
    const [minX, minY, maxX, maxY] = map.getView().calculateExtent();
    const gutter = 10 * map.getView().getResolution();
    const [x, y] = sliderGeometry.getCoordinates();
    sliderGeometry.setCoordinates([
      Math.max(Math.min(x, maxX - gutter), minX + gutter),
      Math.max(Math.min(y, maxY - gutter), minY + gutter)
    ]);
    pixel = map.getPixelFromCoordinate(sliderGeometry.getCoordinates());
  })
  const layer = new VectorLayer({
    zIndex: 16,
    source,
    visible: false,
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    style: [new Style({
      geometry(feature) {
        const extent = map.getView().calculateExtent(map.getSize());
        const [x] = feature.getGeometry().getCoordinates();
        return new LineString([[x, extent[1]], [x, extent[3]]])
      },
      stroke: new Stroke({
        color: '#fff',
        width: 3
      })
    }), new Style({
      text: new Text({
        font: '46px "Material Icons"',
        text: 'swap_horizontal_circle',
        fill: new Fill({
          color: 'rgb(230, 50, 15)'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 6
        })
      })
    })]
  });
  map.addLayer(layer);
  const updateSliderGeometry = (event) => {
    if (!modifying) {
      const coordinate = pixel ? map.getCoordinateFromPixel(pixel) : map.getView().getCenter();
      if (!equals(coordinate, point.getCoordinates())) {
        point.setCoordinates(coordinate);
      }
    }
  };
  layer.on('change:visible', () => {
    if (layer.getVisible()) {
      point.setCoordinates(map.getView().getCenter());
      map.addInteraction(modify);
      map.on('precompose', updateSliderGeometry);
    } else {
      map.removeInteraction(modify);
      map.un('precompose', updateSliderGeometry);
    }
  });
  return layer;
}

// Creates an overlay via a given Referrenz
/**
 * @param {HTMLElement} refElement 
 * @returns {Overlay}
 */
export const createBasicOverlay = (refElement) => {
  return new Overlay({
    element: refElement,
    positioning: 'top-center',
    offset: [0, 15],
    autoPan: {
      margin: 50,
      animation: {
        duration: 250
      },
    },
  });
}

// Create basic view with center aroung Austria Center
const center = fromLonLat([16.37248, 48.21873]);
export const view = new View({
  //maxResolution: 78271.51696402048,
  center,
  zoom: 15
})


/* ------------------------ some Styling functions for Marker / Pointer-------------------------------------- */


export const styleFunctionMarker = (icon) => (f, r) => {
  return createPointStyle(f.get("name"), icon);
};

const offsetY = {
  place: -12,
  not_listed_location: -12
}

const createPointStyle = (text, icon) => {
  const style = [
    new Style({
      zIndex: 0,
      text: new Text({
        font: '30px "Material Icons"',
        text: icon,
        offsetY: offsetY[icon],
        fill: new Fill({
            color: 'rgb(230, 50, 15)'
        }),
        stroke: new Stroke({
            color: '#fff',
            width: 4
        })
      })
    })
  ];
  if (text) {
    style.push(new Style({
      zIndex: 1,
      text: new Text({
        font: '700 18px sans-serif',
        text,
        fill: new Fill({
          color: 'rgb(230, 50, 15)'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 4
        }),
        textAlign: 'left',
        offsetX: 12
      }),
    }));
  }
  return style;
};

const createMarkSvg = (hover, color) => {
  let mark = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g>`;
  mark += `<path style="fill: ${hover ? '#333' : (color ?? 'rgba(230, 50, 15, 1)')}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>`;
  mark += `</g></svg>`;
  return mark;
};


const labelStyling = (label) => {
  return new Text({
    font: "14px Calibri,sans-serif",
    fill: new Fill({ color: "#333" }),
    stroke: createStroke("#fff", 6),
    offsetY: -40, //10
    text: label
  });
}

const createStroke = (color, width) => {
  return new Stroke({
    color: color,
    width: width
  });
};



