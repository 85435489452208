import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Grid, Box } from "@mui/material";
import { newLegendSVG } from "common/constants";
import Icon from '@mui/material/Icon';
import { useTranslation } from "react-i18next";
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { BrowserRouter, Link } from 'react-router-dom';

function legendKey(zoom) {
    let returnKey = newLegendSVG.triggers[0];
    Object.keys(newLegendSVG.triggers).forEach(trigger => {
        if (zoom >= trigger) {
            returnKey = newLegendSVG.triggers[trigger];
        }
    });
    return returnKey;
}


const epoLegend = [
    {karte: 'epo_1', transkey: 'symbol_description', nameaddon: '1:25.000 (1875)', filename: 'Z1_01_1875.pdf'},
    {karte: 'epo_1', transkey: 'symbol_description', nameaddon: '1:200.000 (1904)', filename: 'Z1_02_1904.pdf'},
    {karte: 'epo_1', transkey: 'symbol_description', nameaddon: '1:900.000 (1888)', filename: 'Z1_03_1888.pdf'},
    {karte: 'epo_2', transkey: 'symbol_description', nameaddon: '1:75.000 (1904)', filename: 'Z2_01_1904.pdf'},
    {karte: 'epo_2', transkey: 'symbol_description', nameaddon: '1:200.000 (1904)', filename: 'Z2_02_1904.pdf'},
    {karte: 'epo_2', transkey: 'symbol_description', nameaddon: '1:900.000 (1914)', filename: 'Z2_03_1914.pdf'},
    {karte: 'epo_3', transkey: 'symbol_description', nameaddon: '1:75.000 (1930)', filename: 'Z3_01_1930.pdf'},
    {karte: 'epo_3', transkey: 'symbol_description', nameaddon: '1:200.000 (1929)', filename: 'Z3_02_1929.pdf'},
    {karte: 'epo_3', transkey: 'symbol_description', nameaddon: '1:750.000 (1936)', filename: 'Z3_03_1936.pdf'},
    {karte: 'epo_4', transkey: 'symbol_description', nameaddon: '1:50.000 (1954)', filename: 'Z4_01_1954.pdf'},
    {karte: 'epo_4', transkey: 'symbol_description', nameaddon: '1:200.000 (1957)', filename: 'Z4_02_1957.pdf'},
    {karte: 'epo_4', transkey: 'symbol_description', nameaddon: '1:500.000 (1960)', filename: 'Z4_03_1960.pdf'},
    {karte: 'epo_5', transkey: 'symbol_description', nameaddon: '1:50.000 (1976)', filename: 'Z5_01_1976.pdf'},
    {karte: 'epo_5', transkey: 'symbol_description', nameaddon: '1:200.000 (1979)', filename: 'Z5_02_1979.pdf'},
    {karte: 'epo_5', transkey: 'symbol_description', nameaddon: '1:500.000 (1986)', filename: 'Z5_03_1986.pdf'},
    {karte: 'epo_6', transkey: 'symbol_description', nameaddon: '1:50.000 (1976)', filename: 'Z6_01_1976.pdf'},
    {karte: 'epo_6', transkey: 'symbol_description', nameaddon: '1:200.000 (1979)', filename: 'Z6_02_1979.pdf'},
    {karte: 'epo_6', transkey: 'symbol_description', nameaddon: '1:500.000 (1986)', filename: 'Z6_03_1986.pdf'},
    {karte: 'epo_7', transkey: 'symbol_description', nameaddon: '1:50.000 (2008)', filename: 'Z7_01_2008.pdf'},
    {karte: 'epo_7', transkey: 'symbol_description', nameaddon: '1:200.000 (1979)', filename: 'Z7_02_1979.pdf'},
    {karte: 'epo_7', transkey: 'symbol_description', nameaddon: '1:500.000 (2006)', filename: 'Z7_03_2006.pdf'}
]

const MapLegend = ({ open, closeFunction, searchOpen, zoom, basis, comparison, histmode }) => {
    const { t, i18n } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    const prevKey = React.useRef();
    React.useEffect(() => {
        if (prevKey != legendKey(zoom)) {
            setExpanded(null);
        }
        prevKey.current = legendKey(zoom);
    }, [zoom]);

    const lang = i18n.language.toLowerCase().includes("de") ? 'deutsch' : 'englisch'



    if (!open) return null
    return (
        <BrowserRouter>
        <Paper
            elevation={2}
            sx={{
                p: '2px 4px',
                position: 'fixed',
                top: (theme) => searchOpen ? `calc(143px + ${theme.spacing(6)})` : `calc(117px + ${theme.spacing(6)})`,
                left: (theme) => theme.spacing(2),
                width: t => ({
                    xs: `calc(100% - ${t.spacing(4)})`,
                    md: '400px',
                }),
                height: (theme) => searchOpen ? `calc(100% - ${theme.spacing(32)})` : `calc(100% - ${theme.spacing(27)})`,
                backgroundColor: '#fff',
                borderRadius: '0.5em',
                fontSize: '0.7em',
                padding: '0 0 0 0',
                zIndex: (theme) => theme.zIndex.drawer + 2,
                border: t => `1px solid ${t.palette.primary.light}`,
                transition: 'width ease 250ms',
                boxSizing: 'border-box',
                transitionDelay: '250ms',
            }}
        >
            <Grid container sx={{ fontSize: '1rem', borderBottom: t => `1px solid ${t.palette.divider}` }}  >
                <Grid container item xs={1}>
                    <IconButton onClick={closeFunction} sx={{ pl: '14px' }} ><Icon title={t("icon_close")} aria-label="toggle legend">close</Icon></IconButton>
                </Grid>
                <Grid container item xs={9} sx={{ pl: '16px', py: '10px' }}>{t("legend_all_maps")}</Grid>
                <Grid container item xs={2} justifyContent="flex-end">
                    <Icon title={t("icon_legend")} sx={{ m: '10px', mr: '14px', color: '#666' }}>info</Icon>                    
                </Grid>
            </Grid>

            <Box
                sx={{
                    height: t => `calc(100% - ${t.spacing(6)})`,
                    overflow: "hidden",
                    overflowY: "scroll"
                }}
            >

                {(basis.substring(0,3) === 'epo') && 
                    <Box sx={{ backgroundColor: '#f8f8f8', padding: 1, fontSize: '1rem', borderTop: t => `1px solid ${t.palette.divider}`, borderBottom: t => `1px solid ${t.palette.divider}` }}>{t("modal_layer_" + basis)}</Box>
                }

                {basis.substring(0,3) === 'epo' && epoLegend.filter(e => e.karte === basis).map((element, index) =>
                    <Link to={`/pdf/${element.filename}`} target='_blank'>
                        <Grid container sx={{ color: 'black', fontSize: '1rem' }}  >
                            <Grid href="\" container item xs={8} sx={{ p: '5px' }}><Icon sx={{ mr: 2, color: '#666' }}>download</Icon> { t(element.transkey) + " " + element.nameaddon }</Grid>
                        </Grid>
                    </Link>
                )}

                {(histmode && comparison.substring(0,3) === 'epo') && 
                    <Box sx={{ backgroundColor: '#f8f8f8', padding: 1, fontSize: '1rem', borderTop: t => `1px solid ${t.palette.divider}`, borderBottom: t => `1px solid ${t.palette.divider}` }}>{t("modal_layer_" + comparison)}</Box>
                }

                {histmode && comparison.substring(0,3) === 'epo' && epoLegend.filter(e => e.karte === comparison).map((element, index) =>
                    <Link to={`/pdf/${element.filename}`} target='_blank'>
                        <Grid container sx={{ color: 'black', fontSize: '1rem' }}  >
                            <Grid href="\" container item xs={8} sx={{ p: '5px' }}><Icon sx={{ mr: 2, color: '#666' }}>download</Icon> { t(element.transkey) + " " + element.nameaddon }</Grid>
                        </Grid>
                    </Link>
                )}

                {(basis === 'ortho' || (histmode && comparison === 'ortho')) && 
                    <div>
                        <Box sx={{ backgroundColor: '#f8f8f8', padding: 1, fontSize: '1rem', borderTop: t => `1px solid ${t.palette.divider}`, borderBottom: t => `1px solid ${t.palette.divider}` }}>Orthofoto</Box>
                        <Box sx={{ padding: 1, fontSize: '1rem'}}>Keine Legende verfügbar</Box>
                    </div>
                }


                {(basis === 'karte' || (histmode && comparison === 'karte')) && 
                    <Box sx={{ backgroundColor: '#f8f8f8', padding: 1, fontSize: '1rem', borderTop: t => `1px solid ${t.palette.divider}`, borderBottom: t => `1px solid ${t.palette.divider}` }}>Karte {newLegendSVG.titles[legendKey(zoom)]}</Box>
                }

                {(basis === 'karte' || (histmode && comparison === 'karte')) && newLegendSVG.categories[legendKey(zoom)].map((element, index) =>
                    <Accordion key={index}
                        disableGutters
                        elevation={0}
                        expanded={expanded === 'panel' + (index + 1)}
                        onChange={handleChange('panel' + (index + 1))}
                        sx={{
                            '&:last-of-type': { borderRadius: 0 },
                            borderBottom: t => `1px solid ${t.palette.divider}`,
                        }}>
                        <AccordionSummary
                            sx={{ borderBottom: (expanded === 'panel' + (index + 1)) ? '1px solid #eee' : 'none' }}
                            expandIcon={<Icon>expand_more</Icon>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '80%', flexShrink: 0, fontWeight: expanded === 'panel' + (index + 1) ? 'bold' : 'normal' }}>
                                {t(newLegendSVG.translation[element])}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <img style={{ 'marginTop': '-10px' }} alt="legend entry" width="100%" src={`${process.env.PUBLIC_URL}/legends/${legendKey(zoom)}/${newLegendSVG.prefixes[legendKey(zoom)]}_${element}_${lang}.svg`}></img>
                            {/* <img style={{ 'marginTop': '-10px'}} alt="legend entry" width="100%" src={`${process.env.PUBLIC_URL}/legends/${legendKey(zoom)}/${newLegendSVG.prefixes[legendKey(zoom)]}_Neuausgabe_deutsch_2022_005.svg`}></img>*/}
                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
        </Paper>
        </BrowserRouter>
    )

}

export default MapLegend
