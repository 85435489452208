import * as React from 'react';
import clock from 'assets/img/uhr-200x550.png';
import Paper from '@mui/material/Paper';
import { Box, Button, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";

const AdvertisingClock = ({historyButtonActive, clockAdActive, animationEnded, clockAdFinished}) => {
  const { t } = useTranslation();

  const clockStyle = {
        position: 'fixed',
        top: '0px',
        right: '200px',
        height: '550px',
        width: '200px',
        background: `url(${clock})`,
        zIndex: 1000000,
        transformOrigin: 'top center',
        animation: 'moveIt 4s 1s ease-in-out',
        opacity: 0
    }

    if (!localStorage.getItem('cookieInfoRead') || localStorage.getItem('clockShown')) {
      return null;
    }

    if (!clockAdActive && historyButtonActive) {
      return null;
    }

    if (!historyButtonActive && clockAdActive) {
      return (
        <Paper elevation={0} sx={{

          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          position: 'fixed',
          backgroundColor: 'rgba(0,0,0,.4)',
          borderRadius: 0,
          zIndex: (theme) => theme.zIndex.drawer,
        
        }}>
          <div
              style={ clockStyle }
              onAnimationEnd={() => {animationEnded()}}
          >
          <style>{`
              @keyframes moveIt {
                0% { transform: rotate(-120deg); opacity: 1; }
                20% { transform: rotate(70deg); opacity: 1; }
                45% { transform: rotate(-60deg); opacity: 1; }
                70% { transform: rotate(50deg); opacity: 1; }
                90% { opacity: 1; }
                100% { transform: rotate(-45deg); opacity: 0;  }
              }
          `}</style>
          </div>
          </Paper>          
      );
    }

    if (historyButtonActive && clockAdActive) {
      return (
        <Paper elevation={0} sx={{

          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          position: 'fixed',
          backgroundColor: 'rgba(0,0,0,.4)',
          borderRadius: 0,
          zIndex: (theme) => theme.zIndex.drawer,
        
        }}>
          <Paper
          elevation={2}
          sx={{
              position: 'fixed',
              width: 600,
              maxWidth: 'calc(100vw - 130px)',
              maxHeight: theme => `calc(100vh - ${theme.spacing(24)})`,
              top: theme => theme.spacing(11),
              right: theme => theme.spacing(15),
              zIndex: theme => theme.zIndex.drawer + 1,
              flexDirection: 'column',
              borderRadius: '0.5em',
              display: 'flex',
              padding: (theme) => theme.spacing(2)
          }}
          >
            <Box
                sx={{
                    height: t => `calc(100% - ${t.spacing(12)})`,
                    maxHeight: theme => `calc(100vh - ${theme.spacing(16)})`,
                    overflow: "hidden",
                    overflowY: "auto",
                    marginBottom: 2
                }}>
                  <h1>{t("advertising_clock_title")}</h1>
                  <h2>{t("advertising_clock_subtitle")}</h2>
                  <p>{t("advertising_clock_text_1")}</p>
                  
                  <p>{t("advertising_clock_text_2")} 
                    <Icon sx={{ fontSize: '22px', borderRadius: '5px', pt: '3px', mx: 1, verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.64)', width: 32, height: 32, border: '1px solid'}}>restore</Icon>
                    {t("advertising_clock_text_3")}
                    <Icon sx={{ fontSize: '22px', borderRadius: '5px', pt: '3px', mx: 1, verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.64)', width: 32, height: 32, border: '1px solid'}}>layers</Icon>
                  </p>
                  <p>{t("advertising_clock_text_4")} <Icon sx={{ fontSize: '22px', borderRadius: '5px', pt: '3px', mx: 1, verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.64)', width: 32, height: 32, border: '1px solid'}}>info</Icon>{t("advertising_clock_text_5")}
                  <Icon sx={{ fontSize: '22px', borderRadius: '5px', pt: '3px', mx: 1, verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.64)', width: 32, height: 32, border: '1px solid'}}>quiz</Icon> {t("advertising_clock_text_6")}</p>
                  <p>{t("advertising_clock_text_7")} <a href="mailto:kundenservice@bev.gv.at">kundenservice@bev.gv.at</a> {t("advertising_clock_text_7_1")}</p>
                  <p>{t("advertising_clock_text_8")}</p>
            </Box>
            <Button variant='contained' color='primary' onClick={() => {clockAdFinished()}}>{t("advertising_clock_ok")}!</Button>
          </Paper>
          <Paper
              elevation={0}
              sx={{
                position: 'fixed',
                top: (theme) => theme.spacing(38),
                right: (theme) => theme.spacing(9),
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: 0,
                height: 0,
                backgroundColor: "transparent",
                borderColor: "rgba(230, 50, 15, 1)",
                borderStyle: "solid",
                borderRightWidth: 0,
                borderLeftWidth: 30,
                borderTopWidth: 15,
                borderBottomWidth: 15,
                borderLeftColor: "rgba(230, 50, 15, 1)",
                borderTopColor: "transparent",
                borderBottomColor: "transparent",
              }}>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                position: 'fixed',
                bottom: (theme) => theme.spacing(8),
                left: (theme) => theme.spacing(5),
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: 0,
                height: 0,
                backgroundColor: "transparent",
                borderColor: "rgba(230, 50, 15, 1)",
                borderStyle: "solid",
                borderRightWidth: 15,
                borderLeftWidth: 15,
                borderTopWidth: 30,
                borderBottomWidth: 0,
                borderTopColor: "rgba(230, 50, 15, 1)",
                borderLeftColor: "transparent",
                borderRightColor: "transparent",

              }}>
            </Paper>
        </Paper>

      );
    }
  }

export default AdvertisingClock