import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import { useTranslation } from "react-i18next";

const MapHistoryControl = ({showButton, elevated, isHistMode, setIsHistMode, setPlaying}) => {
    const { t } = useTranslation();
    return (showButton ?
        <Paper
            elevation={2}
            sx={{
                position: 'fixed',
                top: (theme) => theme.spacing(37),
                right: (theme) => theme.spacing(2),
                zIndex: (theme) => elevated ? theme.zIndex.drawer + 1 : theme.zIndex.drawer - 3,
                flexDirection: 'column',
                borderRadius: '0.5em',
                display: 'flex',
            }}
        >
            <ButtonGroup
                sx={{ p: '0', m: '0', borderRadius: '0.5em' }}
                orientation="vertical"
                aria-label="vertical contained button group"
                variant={isHistMode ? "contained" : "text"}
                color={isHistMode ? "primary" : "neutral"}
            >
                <Button sx={{ p: { xs: 1, md: 1} }} 
                    title={t('icon_swipe_mode')} 
                    onClick={elevated ? null : () => {setIsHistMode(!isHistMode); setPlaying(false)}}>
                        <Icon>history</Icon>
                </Button>
            </ButtonGroup>
        </Paper>
     : '' );
}

export default MapHistoryControl;