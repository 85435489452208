import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardActionArea, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, IconButton, Icon, Slide, Typography } from "@mui/material";

import LayerCard from "components/layerCard";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const LayerDialog = ({ open, closeFunction, basis, layer, changeBasis, showLayer }) => {
    const { t } = useTranslation();

    const triggerLayer = (nextLayer) => e => {
        if(layer === nextLayer) return showLayer("")(e);
        return showLayer(nextLayer)(e);
    }

    return <Dialog open={open}
        onClose={closeFunction}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="lg">
            <DialogTitle>{t("modal_layer_header_one")}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={closeFunction}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Icon>close</Icon>
            </IconButton>
            <DialogContent>
                <LayerCard selected={basis === "karte"} handleClick={changeBasis('karte')} img={'karte'} alt={"Image"} title={t("modal_layer_karte")} />
                <LayerCard selected={basis === "ortho"} handleClick={changeBasis('ortho')} img={'ortho'} alt={"Image"} title={t("modal_layer_ortho")} />
                <hr></hr>
                <Typography variant="h6" gutterBottom>{t("modal_layer_header_hist")}</Typography>
                <LayerCard selected={basis === "epo_1"} handleClick={changeBasis('epo_1')} img={'epo_1'} alt={"Image"} title={t("modal_layer_epo_1")}  subtitle={t("layer_subtitle_epo_1")} />
                <LayerCard selected={basis === "epo_2"} handleClick={changeBasis('epo_2')} img={'epo_2'} alt={"Image"} title={t("modal_layer_epo_2")}  subtitle={t("layer_subtitle_epo_2")} />
                <LayerCard selected={basis === "epo_3"} handleClick={changeBasis('epo_3')} img={'epo_3'} alt={"Image"} title={t("modal_layer_epo_3")}  subtitle={t("layer_subtitle_epo_3")} />
                <LayerCard selected={basis === "epo_4"} handleClick={changeBasis('epo_4')} img={'epo_4'} alt={"Image"} title={t("modal_layer_epo_4")}  subtitle={t("layer_subtitle_epo_4")} />
                <LayerCard selected={basis === "epo_5"} handleClick={changeBasis('epo_5')} img={'epo_5'} alt={"Image"} title={t("modal_layer_epo_5")}  subtitle={t("layer_subtitle_epo_5")} />
                <LayerCard selected={basis === "epo_6"} handleClick={changeBasis('epo_6')} img={'epo_6'} alt={"Image"} title={t("modal_layer_epo_6")}  subtitle={t("layer_subtitle_epo_6")} />
                <LayerCard selected={basis === "epo_7"} handleClick={changeBasis('epo_7')} img={'epo_7'} alt={"Image"} title={t("modal_layer_epo_7")}  subtitle={t("layer_subtitle_epo_7")} />
            </DialogContent>
        </Dialog>

}

export default LayerDialog;

