import ScaleLine from 'ol/control/ScaleLine';
import React, { useCallback, useEffect, useRef } from 'react';


/**
 * @param {Object} options 
 * @param {import('ol/Map').default} options.map
*/
const MapScaleLineControl = ({ map }) => {
  const mapScaleLineControlRef = useRef(null);

  const assignScaleLine = useCallback(() => {
    const scaleLine = new ScaleLine({className: 'ol-scale-line', target: mapScaleLineControlRef.current});
    scaleLine.setMap(map);
  }, [map]);

  useEffect(() => {
    if (!map) {
      return;
    };
    assignScaleLine();
  }, [map, assignScaleLine]);

  return (
    <div ref={mapScaleLineControlRef}>
      {/* your component's content */}
    </div>
  );
}

export default MapScaleLineControl;